import {
  Divider,
  InputAdornment,
  OutlinedInput,
  IconButton,
  Snackbar,
  Collapse,
  Alert,
  Button,
  TextField,
  Box,
} from "@mui/material";

import {
  Send as SendIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { styled, useTheme, Typography , TablePagination,} from "@mui/material";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { tokens } from "../../theme";
import { useParams } from 'react-router-dom';


const baseUrl = process.env.REACT_APP_API_URL;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Komentar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [listKomentar, setListKomentar] = useState([]);
  const [expandedReplies, setExpandedReplies] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [replyFormData, setReplyFormData] = useState({});
  const [isReplying, setIsReplying] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { id } = useParams();


  // Fungsi untuk mengubah halaman saat pengguna mengganti halaman
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Fungsi untuk mengubah jumlah baris per halaman saat pengguna mengganti opsi baris per halaman
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExpandCollapse = (commentId, depth) => {
    setExpandedReplies((prevExpanded) => ({
      ...prevExpanded,
      [commentId]: {
        ...prevExpanded[commentId],
        [depth]: !prevExpanded[commentId]?.[depth],
      },
    }));
  };

  const handleReplyInputChange = (event, commentId) => {
    setReplyFormData({
      ...replyFormData,
      [commentId]: event.target.value,
    });
  };

  const handleReplySubmit = async (commentId) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const formdata = new URLSearchParams();
      formdata.append("uid", "9767");
      formdata.append("vid", "20");
      formdata.append("description", replyFormData[commentId]);
      formdata.append("parent_id", commentId);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      const response = await fetch(`${baseUrl}/coment/replay`, requestOptions);
      const result = await response.text();

      setSnackbarMessage(result);
      setSnackbarOpen(true);

      // Reset form dan tutup form balasan
      setReplyFormData({
        ...replyFormData,
        [commentId]: "",
      });
      setIsReplying(false);

      // Fetch ulang data komentar setelah berhasil menambahkan balasan
      fetchData();
    } catch (error) {
      console.error("Error submitting reply:", error);
      setSnackbarMessage("Error submitting reply");
      setSnackbarOpen(true);
    }
  };

  // fetch list Comment
  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/coment/list/${id}`);
      setListKomentar(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching comments:", error);
      setSnackbarMessage("Error fetching comments");
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderRepliesAccordion = (commentId, replies, depth = 1) => (
    <>
      <IconButton
        onClick={() => handleExpandCollapse(commentId, depth)}
        style={{
          transform: expandedReplies[commentId]?.[depth] ? "rotate(180deg)" : "rotate(0deg)",
        }}
      >
        <ExpandMoreIcon />
      </IconButton>
      <Collapse in={expandedReplies[commentId]?.[depth]} timeout="auto" unmountOnExit>
        <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
          {replies?.map((reply) => (
            <div key={reply.id}>
              <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                <Avatar alt="Reply Avatar" src={reply.imgAvatar} />
                <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}>
                  <div>
                    <span style={{ fontWeight: 'bold', marginRight:'10px' }}>@{reply.replay_name || "No Data"}</span>
                    <span> 2 menit yang lalu</span>
                  </div>
                  <span>{reply.description || "No Data"}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );

  const handleBalasClick = (commentId) => {
    setIsReplying(commentId);
  };

  const handleCancelReply = () => {
    setIsReplying(false);
  };

  const renderReplyForm = (commentId) => (
    <div style={{ marginTop: "10px", marginLeft: "60px" }}>
      <OutlinedInput
        fullWidth
        multiline
        name="description"
        rows={2}
        placeholder="Tulis balasan..."
        value={replyFormData[commentId] || ""}
        onChange={(e) => handleReplyInputChange(e, commentId)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={() => handleReplySubmit(commentId)}
              disabled={!replyFormData[commentId]}
            >
              <SendIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </div>
  );

  return (
    <Box m="0 20px">
      <Typography variant="h4" fontWeight="bold" mb={2}>
        Komentar Detail Video
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6">Komentar</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell>Loading...</TableCell>
              </TableRow>
            ) : (
              listKomentar.length > 0 ? (
                listKomentar.map((row) => (
                  <React.Fragment key={row?.id || "no_data"}>
                    <TableRow>
                      <TableCell>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          
                            <div style={{ display: "flex", flexDirection: 'flex-column' }}>
                              <Avatar alt="User Avatar" src={row?.imgAvatar} />
                              <div style={{ display: "block" }}>
                                <div style={{ display: "flex" }}>
                                  <Typography sx={{ mr: 5, ml: 2, fontWeight: 'bold' }} variant="h6">@{row.name || "No Data"}</Typography>
                                  <Typography variant="body1">30 menit yang Lalu</Typography>
                                </div>
                                <Typography sx={{ ml: 2 }} variant="body2">{row.description || "Data Not Available"}</Typography>
                                <div style={{ display: 'flex' }}>
                                  <Typography
                                    sx={{ ml: 2, mr: 2, mt: 2, cursor: 'pointer' }}
                                    variant="body2"
                                    onClick={() => handleBalasClick(row.id)}
                                  >
                                    BALAS
                                  </Typography>
                                  <Typography sx={{ mt: 2 }} variant="subtitle1">
                                    Balasan:
                                  </Typography>
                                  {row.answers.length > 0 && (
                                    <div style={{ display: 'flex', mt: 2, alignItems: 'end' }}>
                                      {renderRepliesAccordion(row.id, row.answers)}
                                    </div>
                                  )}
                                </div>
                                {isReplying === row.id && renderReplyForm(row.id)}
                              </div>
                            </div>
                            {/* ini video */}
                            <div style={{display:'flex'}}>
                              <img src={`${process.env.PUBLIC_URL}/ggl_img.jpg`} style={{maxWidth:'100px'}} />
                              <Typography sx={{ ml: 2 }} variant="subtitle1"> Ini Judul Video </Typography>
                                   
                                 
                            </div>
                       
                        </div>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={1}>
                    <Typography color={colors.grey[100]} variant="h6">
                      Tidak ada komentar yang tersedia.
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listKomentar.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default Komentar;
