import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { Box,FormControl, List,InputLabel,Select,Chip,Checkbox, ListItem, ListItemText,MenuItem, Typography, useTheme, Dialog, DialogTitle, DialogContent, TextField, Button, Snackbar } from "@mui/material";
import Alert from '@mui/material/Alert';
import Header from "../../components/Header";
import { tokens } from "../../theme";
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';

const Calendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [videoOptions, setVideoOptions] = useState([]);
  const [eventFormData, setEventFormData] = useState({
    eventName: "",
    vid: [],
    startDate: "",
    endDate: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const baseUrl = process.env.REACT_APP_API_URL;
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const tokenss = localStorage.getItem('token_contributor');
  const md = localStorage.getItem('md');
  const userId = localStorage.getItem('user_id');
  const browser_id = localStorage.getItem('browser_id');
  const device_id = localStorage.getItem('device_id');
  const app_key = localStorage.getItem('app_key');

  useEffect(() => {
  
      // Panggil API untuk memeriksa status login
      fetchDataFromApi();
  }, []); // Jadikan array dependencies kosong agar useEffect hanya dijalankan sekali saat komponen dimount
  
  const fetchDataFromApi = async () => {
    try {

      const formdata = new FormData();
      // formdata.append("token_contributor",tokenss );
      formdata.append("id", userId );
      // formdata.append("md", md );
      // formdata.append("device_id", device_id);
      // formdata.append("app_key", app_key);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };

      const response = await fetch(`${baseUrl}/auth/check/`, requestOptions);
      const result = await response.json();
      console.log(result);
      setUserData(result); ;

      

      // Jika ditemukan user dengan status_log "online" yang memiliki user_id yang sama, tampilkan pop-up deteksi
      // if (result.status_log === userId && result.md !== md) {
      if (result?.user_id === userId && result?.status_log === "offline") {
        handleLogout();
        // setOpenPopup(true);
      }
        console.log(response.data);// Simpan data respons API ke state
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const handleLogout = async () => {
      try {
        
        const response = await fetch(`${baseUrl}/auth/signout/${userId}`, {
          method: 'POST',
          // Include any necessary headers or credentials for your API request
        });
  
        if (response.ok) {
          localStorage.removeItem('user_id');
          localStorage.removeItem('device_id');
          localStorage.removeItem('browser_id');
          localStorage.removeItem('app_key');
          localStorage.removeItem('md');
          localStorage.removeItem('token_contributor');
          setTimeout(() => {
            navigate('/login');
          }, 2000); 
        } else {
          // Handle errors or display appropriate messages
          console.error('Logout failed:', response.statusText);
        }
      } catch (error) {
        console.error('Error during logout:', error);
      }
    };


   // API Get labels
   const fetchLabels = async () => {
    try {
      const response = await fetch(`${baseUrl}/event/getVideoByCreatedBy/${userId}`);
      const apiData = await response.json();
      if (response.ok) {
        setVideoOptions(apiData.data);
        
      } else {
        console.error('Error fetching data:', apiData.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect( () => {
    fetchLabels()
  })
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}/event/playlist/${userId}`);
        if (response.ok) {
          const data = await response.json();
          
          // Membuat Set untuk menyimpan nama event yang unik
          const uniqueEventNames = new Set();
          const events = data.data.reduce((acc, event) => {
            // Memeriksa apakah nama event sudah ada dalam Set
            if (!uniqueEventNames.has(event.event_name)) {
              uniqueEventNames.add(event.event_name);
              acc.push({
                id: event?.id,
                title: event?.event_name,
                start: `${event?.startdate}T00:00:00`, // Ubah format tanggal
                end: `${event?.enddate}T00:00:00`, // Ubah format tanggal
              });
            }
            return acc;
          }, []);
    
          setCurrentEvents(events);
        } else {
          console.error("Error fetching events:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    

    fetchData();
  }, [baseUrl]);

  const handleDateClick = (selected) => {
    setEventFormData({
      eventName: "",
      startDate: selected.startStr,
      endDate: selected.endStr,
    });
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeSelectlABEL = (name, value) => {
    console.log(value)
    // Jika value bukan array, berarti hanya satu item yang dipilih
    const selectedValues = Array.isArray(value) ? value : [value];

    // const selectedLabelIds = value.map(item => item.label)
      
    // Mengambil nilai id dari item yang dipilih
    const selectedLabelIds = selectedValues.map(selectedLabel => selectedLabel.id).filter(Boolean); // Menyaring nilai yang tidak terdefinisi (undefined)
    console.log(selectedLabelIds)
    setEventFormData((prevData) => ({
      ...prevData,
      [name]: selectedValues,
    }));
    setEventFormData((prevData) => ({
      ...prevData,
      vid: selectedLabelIds,
    }));
  };

  // create event
  const handleCreateEvent = async () => {
    try {
      const formData = new FormData();
      formData.append('vid', `[${eventFormData.vid.join(', ')}]`);
      formData.append('event_name', eventFormData.eventName);
      formData.append('startdate', eventFormData.startDate);
      formData.append('enddate', eventFormData.endDate);
      formData.append('createdBy', userId);
  
      const requestOptions = {
        method: 'POST',
        body: formData,
      };
  
      const response = await fetch(`${baseUrl}/event/createby/${userId}`, requestOptions);
      const responseData = await response.text();
  
      if (response.ok) {
        setOpenModal(false);
        setCurrentEvents(prevEvents => [
          ...prevEvents,
          {
            id: responseData.id,
            title: eventFormData.eventName,
            start: eventFormData.startDate,
            end: eventFormData.endDate,
          },
        ]);
  
        setSnackbarOpen(true);
        console.log(`data yang diinput : ${responseData}`);
        setTimeout(() => {
          setSnackbarOpen(false);
        }, 5000);
      } else {
        console.error('Error creating event:', responseData);
        alert(`Failed to create event. ${responseData}`);
      }
    } catch (error) {
      console.error('Data Yang diinput tidak sesuai Format', error);
      alert(`Failed to create event. ${error.message}`);
    }
  };
  
  const getEventBackgroundColor = (event) => {
    const currentDate = new Date();
    const startDate = new Date(event?.start);
    const endDate = new Date(event?.end);
  
    if (startDate <= currentDate && endDate >= currentDate) {
      return "#4cceac"; // Sedang berlangsung
    } else if (endDate < currentDate) {
      return "#db4f4a"; // Sudah lewat
    } else if (startDate > currentDate) {
      return "#FFC700"; // Sebelum tanggal mulai
    }
  
    return "transparent"; // Default jika tidak ada yang cocok
  };
  

  
  
  return (
    <Box m="20px">
      <Header title="Calendar" subtitle="Full Calendar Interactive Page" />

        {/* Snackbar untuk menampilkan pesan sukses */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} variant="filled" severity="success">
            Event created successfully
          </Alert>
        </Snackbar>
        <Box display="flex" justifyContent="space-between" sx={{ mb: 4 }}>
        {/* ini list item yang ditampilkan */}
        <Box
          flex="1 1 20%"
          backgroundColor={colors.primary[400]}
          p="15px"
          borderRadius="4px"
          sx={{
            overflowY: currentEvents.length > 3 ? 'auto' : 'initial',
            maxHeight: currentEvents.length > 3 ? '455px' : 'auto',
            boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',
            transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
          ':hover': {
            transform: 'scale(1.02)',
            backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
          },
          }}
        >
          <Typography variant="h5" mb={2}>Events</Typography>
          <Box display="flex" alignItems="center">
            <Box width={10} height={10} mr={1} bgcolor="#4cceac"></Box>
            <Typography sx={{fontSize:'10px'}}>Event Berlangsung</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box width={10} height={10} mr={1} bgcolor="#86CBFF"></Box>
            <Typography sx={{fontSize:'10px'}}>Event Yang akan datang</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box width={10} height={10} mr={1} bgcolor="#db4f4a"></Box>
            <Typography sx={{fontSize:'10px'}}>Event sudah berakhir</Typography>
          </Box>
          <List>
            {currentEvents.map((event) => (
              <ListItem
                key={event?.id}
                sx={{
                  backgroundColor: getEventBackgroundColor(event),
                  margin: "10px 0",
                  borderRadius: "2px",
                }}
              >
                <ListItemText
                  primary={event?.title}
                  secondary={
                    <>
                      <Typography>
                        Start :
                        {formatDate(event?.start, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                      </Typography>
                      <Typography>
                        End :
                        {formatDate(event?.end, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>

        <Box flex="1 1 100%" ml="15px" mb="20px">
          <FullCalendar
            height="75vh"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            initialView="dayGridMonth"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            select={handleDateClick}
            events={currentEvents}
            sx={{
            boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',

            }}
          />
        </Box>
      </Box>

      <Dialog open={openModal} onClose={handleModalClose}>
        <DialogTitle>Create Event</DialogTitle>
        <DialogContent>
          <TextField
            label="Event Name"
            name="eventName"
            value={eventFormData.eventName}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />

          <MultipleSelect
            label="Judul Video"
            videoOptions={videoOptions}
            selectedValues={eventFormData.label}
            onChange={(selectedValues) => handleChangeSelectlABEL('label', selectedValues)}
          /> 

          <TextField
            label="Start Date"
            name="startDate"
            value={eventFormData.startDate}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="End Date"
            name="endDate"
            value={eventFormData.endDate}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <Box mt={2} textAlign="right">
            <Button variant="contained" color="secondary" onClick={handleCreateEvent}>
              Create
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Calendar;

const MultipleSelect = ({ label, videoOptions, selectedValues, onChange }) => {
  const handleChange = (event, value) => {
    onChange(value);
  };

  return (
    <FormControl sx={{ width: '100%', paddingTop: '10px' }}>
      {/* <InputLabel>{label}</InputLabel> */}
      <Autocomplete
        multiple
        value={selectedValues}
        onChange={handleChange}
        options={videoOptions}
        getOptionLabel={(option) => option?.name_id}
        renderOption={(props, option, { selected }) => (
          <MenuItem {...props}>
            {/* <Checkbox checked={selected} /> */}
            <ListItemText primary={option?.name_id} />
          </MenuItem>
        )}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </FormControl>
  );
};