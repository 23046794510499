import { useState, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Footer from "./scenes/global/Topbar copy";
import Sidebar from "./scenes/global/Sidebar";
import Analytics from "./scenes/analytics";
import Komentar from "./scenes/komentar";
import DetailKomentar from "./scenes/komentar/detailComment";
import Konten from "./scenes/konten";
import KontenDetail from "./scenes/konten/detail";
import NewKonten from "./scenes/konten/add";
import Dashboard from "./scenes/dashboard";
import Login from "./scenes/Auth/login";
import Register from "./scenes/Auth/register";
import Profile from "./scenes/profile";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import Playlist from "./scenes/playlist";
import DetailPlaylist from "./scenes/playlist/detail";
import Subscriber from "./scenes/subscriber";
// import QRLogin from "./scenes/Auth/QRLogin";
import { isLoggedIn, setLoggedIn } from "./scenes/Auth/AuthService"; // Import fungsi dari authService
import { CircularProgress } from '@mui/material';

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();
  const [prevLocation, setPrevLocation] = useState(null);
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);


  useEffect(() => {
    // Simulasi delay 2 detik
    setTimeout(() => {
      setLoading(false);
      setPrevLocation(pathname);
    }, 1000);
  }, [pathname]);

  // Menggunakan array of paths yang ingin diexclude dari menampilkan Sidebar dan Topbar
  const excludedPaths = ["/login", "/register"];

  const shouldDisplaySidebarAndTopbar = !excludedPaths.includes(pathname);
  const shouldDisplayTopbar = !excludedPaths.includes(pathname);

  const isRouterChange = () => prevLocation !== pathname;

  useEffect(() => {
    // Jika pengguna sudah login dan bukan di halaman login, tetap di halaman yang sedang mereka kunjungi
    if (isLoggedIn() && pathname !== "/login") {
      return; // Jangan lakukan redirect jika pengguna sudah login dan bukan di halaman login
    }
    
    // Jika pengguna belum login dan berada di halaman selain login, arahkan ke halaman login
    if (!isLoggedIn() && pathname !== "/login") {
      // alert('anda harus login terlebih dahulu')
      navigate("/login");
    }
  }, [isLoggedIn, pathname]);

  // <div style={{  position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor:'#141b2d'}}>
    /* <div style={{display:'flex', flexDirection:'column', border:'none', backgroundColor:'white'}}>
    
    // </div> */
    // <img src={`${process.env.PUBLIC_URL}/video-player.gif`} alt="Physics GIF" width="50" />
    // <img src={`${process.env.PUBLIC_URL}/text-erklika.png`} alt="" width="50" style={{backgroundColor:'white',  border:'none'}}/>
    // {/* </div> */}
    if (loading || isRouterChange()) {
      return (
        <div style={{ backgroundColor: theme.palette.mode === 'dark' ? '#141b2d' : '#ffffff', width:'100vw',height:'100vh',
        color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000' }}>
             <div style={{display:'flex', flexDirection:'column', position: 'fixed', border:'none', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                <img src={`${process.env.PUBLIC_URL}/vd4.gif`} alt="Physics GIF" width="80" />

                 {/* Logo sesuai dengan tema yang aktif */}
        {theme.palette.mode === 'dark' ? (
          <img src={`${process.env.PUBLIC_URL}/erklika-putih-logo.png`} alt="Erklika Logo" width="80" style={{  border:'none'}}/>
        ) : (
          <img src={`${process.env.PUBLIC_URL}/text-erklika.png`} alt="Erklika Logo" width="80" style={{  border:'none'}}/>
        )}
          </div> 
    </div>
      );

  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {shouldDisplaySidebarAndTopbar && <Sidebar isSidebar={isSidebar}   />}
          <main className="content">
           
          {shouldDisplaySidebarAndTopbar &&  <Topbar setIsSidebar={setIsSidebar}   />}
            {!shouldDisplaySidebarAndTopbar && (
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
              </Routes>
            )}
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/konten" element={<Konten />} />
              <Route path="/konten-add" element={<NewKonten />} />
              <Route path="/konten-detail/:id" element={<KontenDetail />} />
              <Route path="/komentar" element={<Komentar />} />
              <Route path="/komentar/:id" element={<DetailKomentar />} />
              <Route path="/profile/:id" element={<Profile />} />
              <Route path="/subscriber" element={<Subscriber />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/form" element={<Form />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/playlist" element={<Playlist />} />
              <Route path="/detail-playlist/:eventName" element={<DetailPlaylist />} />
              <Route path="/geography" element={<Geography />} />
              {/* <Route path="/qr-login" element={<QRLogin />} /> Tambahkan rute untuk QR Login */}
            </Routes>
            <div style={{padding:'20px', marginTop:'50px'}}></div>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
