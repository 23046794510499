// LinearProgressWithLabel.jsx
import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const LinearProgressWithLabel = ({ value }) => (
  <Box sx={{ width: '100%' }}>
    <LinearProgress
      variant="determinate"
      value={value}
      sx={{
        height: '10px',   // Tinggi progress bar
        backgroundColor: '#f2f2f2',  // Warna latar belakang progress bar
        '& .MuiLinearProgress-bar': {
          backgroundColor: '#4cceac',  // Warna progress bar
        },
      }}
    />
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ marginTop: 1 }}
    >
      <Typography variant="caption" color="secondary">
        {`${Math.round(value)}%`}
      </Typography>
    </Box>
  </Box>
);

export default LinearProgressWithLabel;
