// fetchUserProfile.js
import { saveUserIdToLocalStorage } from './lc';

export const fetchUserProfile = async () => {
  const myHeaders = new Headers();
  myHeaders.append("app_key", "6261d8a4-c1b4-11ec-9d64-0242ac120002");
  myHeaders.append("device_id", "91266200704");
  myHeaders.append("Authorization", "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjNiYjg3ZGNhM2JjYjY5ZDcyYjZjYmExYjU5YjMzY2M1MjI5N2NhOGQiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiYWxpIG5paCBib3MiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZXJsYXBwcy1jZDNkYSIsImF1ZCI6ImVybGFwcHMtY2QzZGEiLCJhdXRoX3RpbWUiOjE3MDk1MzQ5OTcsInVzZXJfaWQiOiIwVmdMWDdsaGk5UTU4azBDOEJtdzJNTXg3b3YxIiwic3ViIjoiMFZnTFg3bGhpOVE1OGswQzhCbXcyTU14N292MSIsImlhdCI6MTcwOTUzNDk5NywiZXhwIjoxNzA5NTM4NTk3LCJlbWFpbCI6Inp2c2xhbmNlQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbInp2c2xhbmNlQGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.aXgKA7p05A3kHU4f95cyW6VQHptQZZZzO79nQiTeyWXYAKX_ZYWt5AnZNYku0f1Kcr5YBQe8SIXfmLwetQxApqY4RB2jRYE6c4Xp8-7OJ0zBOwpx3MU7t1_9i5_4tdUYe84A9Qb5MCYNjrtMfL9SKX4MtlZkjDobQIhA3XFYmHT4Qp0HTNbrhaRQ5OBLnm36kFHnLHWgFi9I-V-Cn2f3Q-rYC04-ziHoDUd5iW7t0kET7QgDTlkSAbknk9qQvpRqHDwc2ODJEa6ZMkFda9tpqugxj5h2w3RKcbqbsxtJFEyIKjMJDUK52PdLyOvqeo03Vspg3y1Kf7Xa3yJPS7UDmg");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  try {
    const response = await fetch("https://erklika.id:8000/api/v1/user/profile", requestOptions);
    const result = await response.json();

    // Simpan user_id ke dalam localStorage
    if (result && result.data.id) {
      saveUserIdToLocalStorage(result.data.id);
    }

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
