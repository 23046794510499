import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import FileCopy from '@mui/icons-material/FileCopy'; // Import FileCopy icon from Material-UI
import WhatsApp from '@mui/icons-material/WhatsApp';
import CircularProgress from '@mui/material/CircularProgress';
import ScreenSearchDesktopTwoToneIcon from '@mui/icons-material/ScreenSearchDesktopTwoTone';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Container,
  CssBaseline,
  useTheme,
  Box,
  Modal,
  Button,
  IconButton, // Tambahkan import untuk IconButton
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { tokens } from "../../theme";
import Header from "../../components/Header";
import TvTwoToneIcon from '@mui/icons-material/TvTwoTone';
import LabelTwoToneIcon from '@mui/icons-material/LabelTwoTone';

const PlaylistDetail = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [playlistData, setPlaylistData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [viewCounts, setViewCounts] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedVideo, setSelectedVideo] = useState(null); // State untuk menentukan video yang dipilih
  const [isModalOpen, setIsModalOpen] = useState(false); // State untuk menentukan apakah modal dibuka
  const [isLoading, setIsLoading] = useState(true); // State untuk menentukan apakah modal dibuka
  const { eventName } = useParams(); // Mendapatkan eventName dari URL

  const baseUrl = process.env.REACT_APP_API_URL;
  const userId = localStorage.getItem('user_id');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}/event/playlist/${userId}`); // Menambahkan userId ke URL
        const data = await response.json();

        if (response.ok) {
          // Filter data berdasarkan nama event yang sesuai
          const filteredData = data.data.filter((video) => video.event_name === eventName);
          setPlaylistData(filteredData);
          setIsLoading(false);

          // Ambil tanggal startdate dan enddate
          if (filteredData.length > 0) {
            setStartDate(filteredData[0].startdate);
            setEndDate(filteredData[0].enddate);
          }

          const counts = {};
          filteredData.forEach((video) => {
            counts[video.event_name] = counts[video.event_name] ? counts[video.event_name] + parseInt(video.views) : parseInt(video.views);
          });
          setViewCounts(counts);
        } else {
          console.error("Error fetching playlist data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching playlist data:", error);
      }
    };

    fetchData();
  }, [userId, eventName]); // Fetch data saat userId atau eventName berubah

  // Fungsi untuk membuka modal dan menetapkan video yang dipilih
  const handleOpenModal = (video) => {
    setSelectedVideo(video);
    setIsModalOpen(true);
  };

  // Fungsi untuk menutup modal
  const handleCloseModal = () => {
    setSelectedVideo(null);
    setIsModalOpen(false);
  };

  // Fungsi untuk menyalin URL video ke clipboard
  const handleCopyURL = () => {
    navigator.clipboard.writeText(selectedVideo.filename_id);
    alert("URL video telah disalin ke clipboard.");
  };

  // Fungsi untuk membagikan URL video ke WhatsApp
  const handleShareWhatsApp = () => {
    const url = `https://wa.me/?text=${encodeURIComponent(selectedVideo.filename_id)}`;
    window.open(url, '_blank');
  };
   const boxShadowStyle = {
    boxShadow: '7px -4px 12px -1px rgba(0,0,0,0.22)',
    WebkitBoxShadow: '7px -4px 12px -1px rgba(0,0,0,0.22)',
    MozBoxShadow: '7px -4px 12px -1px rgba(0,0,0,0.22)'
  };
  const boxShadowStyle2 = {
    boxShadow: '7px -4px 12px -1px rgba(224,224,224,0.22)',
    WebkitBoxShadow: '7px -4px 12px -1px rgba(224,224,224,0.22)',
    MozBoxShadow: '7px -4px 12px -1px rgba(224,224,224,0.22)'
  };

  return (
    <>
      <Box m="0 20px">
        <Container
          maxWidth="lg"
          sx={{
            paddingBottom: theme.spacing(4),
          }}
        >
          <Link  to="/playlist">
            <IconButton mb={2} edge="start" color="inherit" aria-label="back" sx={{textDecoration:'none'}} >
              <ArrowBackIcon sx={{ fontSize: '25px', float:'left', }}/>
          </IconButton>

          </Link>

          <Header title={`Detail Playlist`} subtitle="Display playlist video" />
          <CssBaseline />
          {isLoading ? (
            // <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
            //   <ScreenSearchDesktopTwoToneIcon sx={{fontSize:250}}/>
            //   <h3>Tidak ada Video Dalam Playlist</h3>
            // </Box>
            <Box sx={{textAlign:'center', top:50, left:50, }}><CircularProgress color="inherit" /></Box>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography mb={1} sx={{fontWeight:'bold'}} variant="h5">Nama Event:</Typography>
                <span style={{fontWeight:'bold',backgroundColor:'#d8dbe2', borderRadius:'8px', padding:'5px', color:'black'}}>{eventName}</span>
                

                <Typography  mt={2} sx={{fontWeight:'bold'}} variant="h5">Waktu Event Berlangsung:</Typography>
                <Box sx={{display:'flex'}} mt={1}>
                    <Typography sx={{mr:3, backgroundColor:'#d8dbe2', borderRadius:'8px', padding:'5px', color:'black'}} color="secondary" variant="body1">Tanggal Mulai: {startDate}</Typography>
                    <Typography sx={{backgroundColor:'#d8dbe2', borderRadius:'8px', padding:'5px', color:'black'}} color="secondary" variant="body1">Tanggal Selesai: {endDate}</Typography>
                </Box>
              </Grid>
              {playlistData.map((video) => (
                <Grid item key={video.id} xs={12} sm={6} md={4} lg={3}>
                  <Card
                    sx={{
                      minWidth: 235,
                      margin: theme.spacing(1),
                      backgroundColor: colors.primary[400],
                      boxShadow: theme.palette.mode === 'dark' ? boxShadowStyle2 : boxShadowStyle,
                      transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
                      ':hover': {
                        transform: 'scale(1.02)',
                        backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
                      },
                      borderRadius:'15px'
                    }}
                  >
                    <CardActionArea onClick={() => handleOpenModal(video)}> {/* Tambahkan event onClick untuk membuka modal */}
                      <CardMedia
                        sx={{ height: 130, borderRadius:'15px' }}
                        component="img"
                        alt={`Thumbnail for ${video.event_name}`}
                        image={video.thumbnail_id}
                      />
                      <CardContent>
                        <Typography variant="body2" style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize:'15px',
                            fontWeight:'bold'
                          }}>
                          {video.name_id}
                        </Typography>
                        <Box >
                          <Box display="flex" alignItems="center" >
                            <TvTwoToneIcon fontSize="small"  />
                            <Typography ml={2} variant="body2" component="span" sx={{paddingLeft:'5px'}}>{viewCounts[video.event_name] ? `${viewCounts[video.event_name]} x ditonton` : '0 x ditonton'}</Typography>
                            

                          </Box>
                        
                          <Box display="flex" alignItems="center" mt={1}>
                            <LabelTwoToneIcon fontSize="medium"  />
                          <Typography ml={2}  variant="body2" component="span">
                            <span style={{backgroundColor:'#d8dbe2', borderRadius:'8px', padding:'5px', marginRight:'10px', color:'black'}}>SMA</span>
                            <span style={{backgroundColor:'#d8dbe2', borderRadius:'8px', padding:'5px', color:'black'}}>Informatika</span>
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}

          {/* Modal untuk menampilkan video preview */}
          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: 800,
                bgcolor: 'background.paper',
                border: '2px solid #94e2cd',
                borderRadius:'10px',
                boxShadow: 24,
                p: 4,
              }}
            >
              <video controls style={{ width: '100%' }}>
                <source src={selectedVideo?.filename_id} type="video/mp4" />
              </video>

              <Box sx={{float:'right'}}>

              <IconButton onClick={handleCopyURL} color={colors.greenAccent[700]}> {/* Tambahkan IconButton untuk menyalin URL */}
                <FileCopy />
              </IconButton>
              <IconButton onClick={handleShareWhatsApp} color={colors.greenAccent[700]}> {/* Tambahkan IconButton untuk membagikan ke WhatsApp */}
                <WhatsApp />
              </IconButton>
              </Box>
            </Box>
          </Modal>
        </Container>
      </Box>
    </>
  );
};

export default PlaylistDetail;
