import {
  Divider,
  InputAdornment,
  OutlinedInput,
  IconButton,
  Snackbar,
  Collapse,
  Alert,
  Button,
  TextField,
  Box,
} from "@mui/material";

import {
  Send as SendIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { styled, useTheme, Typography , TablePagination,} from "@mui/material";
import { Fade } from "react-reveal";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { tokens } from "../../theme";
import { useParams, useNavigate } from 'react-router-dom';
import Header from "../../components/Header";
import CircularProgress from '@mui/material/CircularProgress';



const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Komentar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [listKomentar, setListKomentar] = useState([]);
  const [expandedReplies, setExpandedReplies] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isReplying, setIsReplying] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [replyFormData, setReplyFormData] = useState({
    vid: null,
  });

  const baseUrl = process.env.REACT_APP_API_URL;
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const tokenss = localStorage.getItem('token_contributor');
  const md = localStorage.getItem('md');

  const browser_id = localStorage.getItem('browser_id');
  const device_id = localStorage.getItem('device_id');
  const app_key = localStorage.getItem('app_key');

  useEffect(() => {
  
      // Panggil API untuk memeriksa status login
      fetchDataFromApi();
  }, []); // Jadikan array dependencies kosong agar useEffect hanya dijalankan sekali saat komponen dimount
  
  const fetchDataFromApi = async () => {
    try {

      const formdata = new FormData();
      // formdata.append("token_contributor",tokenss );
      formdata.append("id", userId );
      // formdata.append("md", md );
      // formdata.append("device_id", device_id);
      // formdata.append("app_key", app_key);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };

      const response = await fetch(`${baseUrl}/auth/check/`, requestOptions);
      const result = await response.json();
      console.log(result);
      setUserData(result); ;

      

      // Jika ditemukan user dengan status_log "online" yang memiliki user_id yang sama, tampilkan pop-up deteksi
      // if (result.status_log === userId && result.md !== md) {
      if (result?.user_id === userId && result?.status_log === "offline") {
        handleLogout();
        // setOpenPopup(true);
      }
        console.log(response.data);// Simpan data respons API ke state
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const handleLogout = async () => {
      try {
        
        const response = await fetch(`${baseUrl}/auth/signout/${userId}`, {
          method: 'POST',
          // Include any necessary headers or credentials for your API request
        });
  
        if (response.ok) {
          localStorage.removeItem('user_id');
          localStorage.removeItem('device_id');
          localStorage.removeItem('browser_id');
          localStorage.removeItem('app_key');
          localStorage.removeItem('md');
          localStorage.removeItem('token_contributor');
          setTimeout(() => {
            navigate('/login');
          }, 2000); 
        } else {
          // Handle errors or display appropriate messages
          console.error('Logout failed:', response.statusText);
        }
      } catch (error) {
        console.error('Error during logout:', error);
      }
    };
  
  const [formattedCreatedAt, setFormattedCreatedAt] = useState('');

  const formatTimeAgo = (timestamp) => {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const timeDifference = now - createdAt;

    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (minutes < 1) {
      return 'Baru saja';
    } else if (minutes < 60) {
      return `${minutes} menit yang lalu`;
    } else if (hours < 24) {
      return `${hours} jam yang lalu`;
    } else {
      return `${days} hari yang lalu`;
    }
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleExpandCollapse = (commentId, depth) => {
    setExpandedReplies((prevExpanded) => ({
      ...prevExpanded,
      [commentId]: {
        ...prevExpanded[commentId],
        [depth]: !prevExpanded[commentId]?.[depth],
      },
    }));
  };

  const handleReplyInputChange = (event, commentId) => {
    setReplyFormData({
      ...replyFormData,
      [commentId]: event.target.value,
    });
  };

  const { id } = useParams();
  const userId = localStorage.getItem('user_id');

  const handleReplySubmit = async (commentId) => {
    try {

      // Ambil vid dari komentar yang akan dibalas
    const vid = listKomentar.find((comment) => comment.id === commentId)?.vid;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const formdata = new URLSearchParams();
      formdata.append("uid", userId);
      formdata.append("vid", vid); 
      formdata.append("description", replyFormData[commentId]);
      formdata.append("parent_id", commentId);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      const response = await fetch(`${baseUrl}/coment/replay`, requestOptions);
      const result = await response.text();

      setSnackbarMessage(result);
      setSnackbarOpen(true);

      // Reset form dan tutup form balasan
      setReplyFormData({
        ...replyFormData,
        [commentId]: "",
      });
      setIsReplying(false);

      // Fetch ulang data komentar setelah berhasil menambahkan balasan
      fetchData();
    } catch (error) {
      console.error("Error submitting reply:", error);
      setSnackbarMessage("Error submitting reply");
      setSnackbarOpen(true);
    }
  };

  // fetch list Comment
  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/coment/listVideoBy/${userId}`);
      setListKomentar(response.data.data);
      console.log(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching comments:", error);
      setSnackbarMessage("Error fetching comments");
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderRepliesAccordion = (commentId, replies, depth = 1) => (
    <>
      <IconButton
        onClick={() => handleExpandCollapse(commentId, depth)}
        style={{
          transform: expandedReplies[commentId]?.[depth] ? "rotate(180deg)" : "rotate(0deg)", width:'20px', marginTop:'7px'
        }}
      >
        <ExpandMoreIcon />
      </IconButton>
      <Collapse in={expandedReplies[commentId]?.[depth]} timeout="auto" unmountOnExit>
        <div style={{ display: "flex", flexDirection: "column", justifyContent:'start', marginTop: "50px" }}>
          {replies?.map((reply) => (
            <div key={reply.id}>
              <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                <Avatar alt="Reply Avatar" src={reply.imgAvatar} />
                <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}>
                  <div>
                    <span style={{ fontWeight: 'bold', marginRight:'10px' }}>@{reply.replay_name || "No Data"}</span>
                    <span> {formatTimeAgo(reply.createdAt)}</span>
                  </div>
                  <span>{reply.description || "No Data"}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );

  const handleBalasClick = (commentId) => {
    const vid = listKomentar.find((comment) => comment.id === commentId)?.vid;

    setIsReplying({ commentId, vid });
  };

  const handleCancelReply = () => {
    setIsReplying(false);
  };

  const renderReplyForm = (commentId, vid) => (
    <div style={{ marginTop: "10px", marginLeft: "60px" }}>
      <OutlinedInput
        fullWidth
        multiline
        name="description"
        rows={2}
        placeholder="Tulis balasan..."
        value={replyFormData[commentId] || ""}
        onChange={(e) => handleReplyInputChange(e, commentId)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={() => handleReplySubmit(commentId, vid)}
              disabled={!replyFormData[commentId]}
            >
              <SendIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </div>
  );

  const color = theme.palette.mode === 'dark' ? '#000000' : '#ffffff';
  const background = theme.palette.mode === 'dark' 
  ? 'linear-gradient(135deg, #ffffff, #03dac5, #32b3a8)' 
  : '#6870FA';
  return (
    <Box m="0 20px">
      <Header title="Komentar channel" subtitle="All Comments User Displayed" />
      <Fade left>

      <Paper 
        sx={{ width: '100%', overflow: 'hidden',
        boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',

        }}>
          <TableContainer  sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{background}}>
                <TableRow>
                  <TableCell sx={{background, color}} >
                    <Typography variant="h6">Komentar</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell sx={{textAlign:'center'}}><CircularProgress color="inherit" /></TableCell>
                  </TableRow>
                ) : (
                  listKomentar
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row) => (
                      <React.Fragment key={row?.id || "no_data"}>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                
                                  <div style={{ display: "flex", flexDirection: 'flex-column' }}>
                                    <Avatar alt="User Avatar" src={row?.imgAvatar} />
                                    <div style={{ display: "block" }}>
                                      <div style={{ display: "flex" }}>
                                        <Typography sx={{ mr: 5, ml: 2, fontWeight: 'bold', backgroundColor:'' }} variant="h6">@{row.name || "No Data"}</Typography>
                                        <Typography sx={{ fontSize:'12px'}} variant="body1">{formatTimeAgo(row?.createdAt)}</Typography>
                                      </div>
                                      <Typography sx={{ ml: 2 }} variant="body2">{row.description || "Data Not Available"}</Typography>
                                      <div style={{ display: 'flex' }}>
                                        <Typography
                                          sx={{ ml: 2, mr: 2, mt: 2, cursor: 'pointer' }}
                                          variant="body2"
                                          onClick={() => handleBalasClick(row?.id)}
                                        >
                                          BALAS
                                        </Typography>
                                        
                                        <Typography sx={{ mt: 2, fontSize:'10px' }} variant="subtitle1">
                                        {Object.values(row.answers || {}).reduce((total, replies) => total + replies.length, 0)}  balasan
                                        </Typography>
 
                                        {row.answers && Object.keys(row.answers).length > 0 && (
                                          <div style={{ display: 'flex', mt: 2, alignItems: 'end' }}>
                                            {Object.values(row?.answers).map((replies) => (
                                              renderRepliesAccordion(row?.id, replies)
                                            ))}
                                          </div>
                                        )}

                                      </div>
                                      {isReplying && isReplying.commentId === row?.id && (
                                        renderReplyForm(row?.id, isReplying.vid)
                                      )}
                                    </div>
                                  </div>


                                  {/* ini video */}
                                    <div style={{marginRight:'50px', display:'flex', justifyContent:'space-between', width:'250px'}}>
                                      <div >
                                        <img src={row.imgPath} style={{ maxWidth: '100px', flex: '0 0 auto' }} />
                                        {/* <img src={`${process.env.PUBLIC_URL}/ggl_img.jpg`} style={{ maxWidth: '100px', flex: '0 0 auto' }} /> */}

                                      </div>
                                      
                                        <Typography sx={{ ml: 2, overflow: 'hidden', textOverflow: 'ellipsis',}} variant="subtitle1">
                                          {row?.judul}
                                        </Typography>
                                      {/* <div style={{  marginLeft: '10px', borderLeft:'1px sold black' }}>
                                      </div> */}

                                    </div>
                                  </div>
                            </TableCell>
                          </TableRow>
                      </React.Fragment>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
      </Paper>
      </Fade>
      <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listKomentar.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Box>
  );
};

export default Komentar;
