import React, { useState, useEffect } from 'react';
import { Alert, Snackbar, Box, Typography, styled, useTheme, Avatar, Tabs, Tab, TextField, Button, IconButton } from '@mui/material';
import { tokens } from "../../theme";
import SaveIcon from '@mui/icons-material/Save';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { useParams } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import DevicesIcon from '@mui/icons-material/Devices';
import Header from "../../components/Header";


const StyledBox1 = styled(Box)({
  position: 'absolute',
  left: 0,
  top: '150px',
  zIndex: -1,
  // backgroundImage: 'linear-gradient(to left bottom, #19beb7, #77ceb7, #adddc0, #d8ecd4, #f9fdf1)',
  background: 'linear-gradient(135deg, #ffffff, #6dd5fa, #2980b9)',

  width: '100%',
  height: '100px',
  padding: '100px',
});

const StyledBox2 = styled(Box)({
  position: 'absolute',
  width: '80%',
  padding: '5px',
  display: 'flex',
  marginTop: '80px',
  flexDirection: 'column',
  justifyContent: 'center', 
  alignItems: 'center',
});

const StyledChild1 = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#353551' : '#F0F0F0',
  padding: '40px',
  borderRadius: '10px',
  display: 'flex',
  width: '95%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Menambahkan shadow
}));

const StyledChild2 = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1E1E3B' : '#F0F0F0',
  padding: '10px',
  borderRadius: '10px',
  display: 'flex',
  width: '95%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
 
}));


interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const App = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [data, setData] = useState({
    full_name: '',
    // email: '',
    phone: '',
    address: '',
    avatar: '',
  });

  const { id } = useParams();
  const baseUrl = process.env.REACT_APP_API_URL;

  //  API Get data by ID
  const userProfile = async () => {
    try {
      const response = await fetch(`${baseUrl}/users/profil/${id}`);
      const apiData = await response.json();
      if (response.ok) {
        setData(apiData.data[0]);
      } else {
        console.error('Error fetching data:', apiData.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    userProfile()
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setTabValue(index);
  };

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  
  //   reader.onloadend = () => {
  //     setSelectedImage(reader.result);
  //   };
  
  //   if (file) {
  //     reader.readAsDataURL(file);
  //   } else {
  //     setSelectedImage(null);
  //   }
  // };
  
  
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validasi jenis file gambar
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      if (!allowedTypes.includes(file.type)) {
        alert('Gambar harus dalam format JPEG, PNG, atau JPG');
        return;
      }

      // Validasi ukuran file
      if (file.size > 1024 * 1024 * 10) {
        alert('Gambar harus kurang dari 10MB');
        return;
      }

      setSelectedImage(file);
      setData((prevData) => ({
        ...prevData,
        avatar: file,
      }));
    }
  };

  const handleChangeInput = (propertyName) => (e) => {
    setData(prevData => ({
      ...prevData,
      [propertyName]: e.target.value
    }));
  };

  const handleUpdateData = async () => {
    try {
      const formData = new FormData();
      formData.append('full_name', data.full_name);
      formData.append('phone', data.phone);
      formData.append('address', data.address);
      formData.append('avatar', data.avatar);
      // Tambahkan properti lain sesuai kebutuhan
      
      const response = await fetch(`${baseUrl}/users/update_profil/${id}`, {
        method: 'POST',
        body: formData,
      });
  
      const apiData = await response.json();
  
      if (response.ok) {
        setSnackbarOpen(true);
        setTimeout(() => {
          // navigate('/dashboard');
        }, 5000);
        // Tambahkan logika atau tindakan lain yang diperlukan setelah pembaruan data berhasil
      } else {
        console.error('Error updating data:', apiData.error);
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };
  

  return (
    <>
    <Box sx={{marginLeft:'30px'}}>

        <Header  title="Profile" subtitle="Detail your account" />
    </Box>
    <Box  m="0 ">

          <StyledBox1 />
          <StyledBox2>
            <StyledChild1 elevation={6} sx={{ bgcolor: colors.primary[400]}} >
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Alert onClose={() => setSnackbarOpen(false)} variant="filled" severity="success">
                  Update Profile Berhasil
                </Alert>
              </Snackbar>
              <label htmlFor="avatar">
                <IconButton component="span">
                  {selectedImage && (
                    <Avatar
                      src={URL.createObjectURL(selectedImage)}
                      alt="Thumbnail Preview"
                      sx={{ width: 80, height: 80 }} 
                    />
                  )}
                  {!selectedImage && data?.avatar && (
                          <Avatar
                            src={data?.avatar}
                            alt=""
                            style={{ width: 80, height: 80 }}
                          />
                        )}
                  {!selectedImage && data?.avatar == "https://192.168.40.36/erklika-cms-2/assets/upload_avatar/No Avatar available" &&  (
                    <PhotoCameraIcon sx={{ fontSize: 50 }} />
                  )}
                  
                </IconButton>
              </label>
              <VisuallyHiddenInput
                type="file"
                id="avatar"
                onChange={(e) => {
                  handleImageChange(e);
                }}
                name="avatar"
                accept="image/*"
              />
              <Typography variant="h4" color="inherit">{data?.full_name}</Typography>
              <Typography variant="h5" color="secondary">Guru - SMA Negeri 4 Samarinda</Typography>
            </StyledChild1>

            {/* component styledchild1 yang kedua */}
            <StyledChild2 sx={{ bgcolor: colors.primary[400], padding: 0, mt: 2 }} >
              <Box sx={{maxWidth:"60%"}}>

                <Tabs
                value={tabValue}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                sx={{ bgcolor: colors.primary[400], padding: 0 }}
              >
                <Tab sx={{ mr: 2 }}  icon={<PersonIcon />} label="Profile"  {...a11yProps(0)} style={{ color: colors.grey[200] }}/>
                <Tab sx={{ mr: 2 }} icon={<LockIcon />} label="Ubah Password" {...a11yProps(1)} style={{ color: colors.grey[200] }}/>
                <Tab  label="Device" icon={<DevicesIcon />} {...a11yProps(2)} style={{ color: colors.grey[200] }} />
              </Tabs>
                <TabPanel value={tabValue} index={0} dir={theme.direction}>
                  <TextField  name="full_name"  value={data?.full_name} label="Nama Lengkap" fullWidth  margin="normal"  onChange={handleChangeInput('full_name')}/>
                  <TextField name="email"  value={data?.email} label="Email" fullWidth margin="normal" onChange={handleChangeInput('email')}/>
                  <TextField name="phone"  value={data?.phone} label="Nomor Handphone" fullWidth margin="normal" onChange={handleChangeInput('phone')}/>
                  <TextField  name="address" value={data?.address} label="Alamat" fullWidth  multiline rows={4} margin="normal" onChange={handleChangeInput('address')}/>
                  
                  <Button
                    type="submit" 
                    color="secondary"
                    variant="outlined"
                    onClick={handleUpdateData}
                  >
                    <IconButton color="inherit" size="small">
                    <SaveIcon />
                    </IconButton>
                    Simpan Perubahan
                  </Button>
                </TabPanel>
                

                <TabPanel value={tabValue} index={1} dir={theme.direction}>
                  <TextField label="Password lama "  type="password" fullWidth margin="normal" />
                  <TextField label="Password baru" type="password" fullWidth margin="normal" />
                  <TextField label="Password baru" type="password" fullWidth margin="normal" />
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                  
                  >
                    <IconButton color="inherit" size="small">
                    <SaveIcon />
                    </IconButton>
                    Simpan Perubahan
                  </Button>           
                </TabPanel>

                <TabPanel value={tabValue} index={2} dir={theme.direction}>
                  <TextField label="Device ID" fullWidth margin="normal" />
                  <TextField label="Device Name" fullWidth margin="normal" />
                  <TextField label="Device Name" fullWidth margin="normal" />
                </TabPanel>
              </Box>
            </StyledChild2>
          </StyledBox2>

    </Box>
    </>
  

  );
};

export default App;

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});