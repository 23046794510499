import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const ProgressCircle = ({ progress = "0.75", size = "40" }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const angle = progress * 360;

  // // Definisikan warna untuk mode light
  const lightColors = {
    background: `radial-gradient(${colors.primary[400]} 55%, transparent 56%),
              conic-gradient(transparent 0deg ${angle}deg,#E1FF50  ${angle}deg 360deg),
              #6EECFF`,
  };
  // Definisikan warna untuk mode light
  // const lightColors = {
  //   background: `radial-gradient(${colors.primary[400]} 55%, transparent 56%)
  //             conic-gradient(transparent 0deg ${angle}deg,#ffffff  ${angle}deg 360deg),
  //             #000000`,
  // };
  
  // Definisikan warna untuk mode dark
  const darkColors = {
    background: `radial-gradient(#ffffff 55%, transparent 56%),
    conic-gradient(transparent 0deg ${angle}deg,#E1FF50  ${angle}deg 360deg),
    #6EECFF`,
  };
  // // Definisikan warna untuk mode dark
  // const darkColors = {
  //   background: `
  //             conic-gradient(transparent 0deg ${angle}deg,#03dac5  ${angle}deg 180deg),
  //             #FBFB61`,
  // };

  return (
    <Box
      sx={{
        background: theme.palette.mode === 'light' ? lightColors.background : darkColors.background,
        borderRadius: "50%",
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  );
};

export default ProgressCircle;
