import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Container,
  CssBaseline,
  useTheme,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { List, Folder } from "@mui/icons-material"; // Menambahkan impor icon List dan Folder
import SubscriptionsTwoToneIcon from '@mui/icons-material/SubscriptionsTwoTone';

const VideoPlaylist = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [playlistData, setPlaylistData] = useState([]);
  const [totalVideo, setTotalVideo] = useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [isGridView, setIsGridView] = useState(true); // State untuk menentukan apakah tampilan dalam grid atau tabel list

  const baseUrl = process.env.REACT_APP_API_URL;
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const tokenss = localStorage.getItem('token_contributor');
  const md = localStorage.getItem('md');
  const userId = localStorage.getItem('user_id');
  const browser_id = localStorage.getItem('browser_id');
  const device_id = localStorage.getItem('device_id');
  const app_key = localStorage.getItem('app_key');

  useEffect(() => {
  
      // Panggil API untuk memeriksa status login
      fetchDataFromApi();
  }, []); // Jadikan array dependencies kosong agar useEffect hanya dijalankan sekali saat komponen dimount
  
  const fetchDataFromApi = async () => {
    try {

      const formdata = new FormData();
      // formdata.append("token_contributor",tokenss );
      formdata.append("id", userId );
      // formdata.append("md", md );
      // formdata.append("device_id", device_id);
      // formdata.append("app_key", app_key);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };

      const response = await fetch(`${baseUrl}/auth/check/`, requestOptions);
      const result = await response.json();
      console.log(result);
      setUserData(result); ;

      

      // Jika ditemukan user dengan status_log "online" yang memiliki user_id yang sama, tampilkan pop-up deteksi
      // if (result.status_log === userId && result.md !== md) {
      if (result?.user_id === userId && result?.status_log === "offline") {
        handleLogout();
        // setOpenPopup(true);
      }
        console.log(response.data);// Simpan data respons API ke state
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const handleLogout = async () => {
      try {
        
        const response = await fetch(`${baseUrl}/auth/signout/${userId}`, {
          method: 'POST',
          // Include any necessary headers or credentials for your API request
        });
  
        if (response.ok) {
          localStorage.removeItem('user_id');
          localStorage.removeItem('device_id');
          localStorage.removeItem('browser_id');
          localStorage.removeItem('app_key');
          localStorage.removeItem('md');
          localStorage.removeItem('token_contributor');
          setTimeout(() => {
            navigate('/login');
          }, 2000); 
        } else {
          // Handle errors or display appropriate messages
          console.error('Logout failed:', response.statusText);
        }
      } catch (error) {
        console.error('Error during logout:', error);
      }
    };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}/event/playlist/${userId}`);
        const data = await response.json();

        if (response.ok) {
          const uniqueEvents = {};
          const totalVideos = {}; // State baru untuk menyimpan total video
          data.data.forEach((video) => {
            if (!uniqueEvents[video.event_name]) {
              uniqueEvents[video.event_name] = video;
              totalVideos[video.event_name] = 1; // Inisialisasi jumlah video dengan 1
            } else {
              totalVideos[video.event_name]++; // Tambahkan jumlah video jika event_name sudah ada
            }
          });
          const uniqueEventsArray = Object.values(uniqueEvents);
          setPlaylistData(uniqueEventsArray);
          setTotalVideo(totalVideos); // Update state totalVideo
        } else {
          console.error("Error fetching playlist data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching playlist data:", error);
      }
    };

    fetchData();
  }, [baseUrl, userId]);
  
          
  // Fungsi untuk mengubah tampilan antara grid dan tabel list
  const toggleView = () => {
    setIsGridView(!isGridView);
  };

  const formatDateIndonesia = (dateString) => {
    const months = [
      'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
      'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
    ];
  
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
  
    return `${day} ${months[monthIndex]} ${year}`;
  };

    const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getRowsOnPage = () => {
    return playlistData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };
  const color = theme.palette.mode === 'dark' ? '#000000' : '#ffffff';
  const color2 = theme.palette.mode === 'dark' ? '#03dac5' : '#6870FA';
  const background = theme.palette.mode === 'dark' 
  ? '#03dac5' 
  : '#6870FA';

  const boxShadowStyle = {
    boxShadow: '7px -4px 12px -1px rgba(0,0,0,0.22)',
    WebkitBoxShadow: '7px -4px 12px -1px rgba(0,0,0,0.22)',
    MozBoxShadow: '7px -4px 12px -1px rgba(0,0,0,0.22)'
  };
  const boxShadowStyle2 = {
    boxShadow: '7px -4px 12px -1px rgba(224,224,224,0.22)',
    WebkitBoxShadow: '7px -4px 12px -1px rgba(224,224,224,0.22)',
    MozBoxShadow: '7px -4px 12px -1px rgba(224,224,224,0.22)'
  };

  return (
    <>
      <Box m="0 20px">
        <Container
          maxWidth="lg"
          sx={{
            paddingBottom: theme.spacing(4),
          }}
        >
          <Box sx={{display:'flex', justifyContent:'space-between'}}>

          <Header title="Playlist Video" subtitle="Display playlist video">
            {/* Memasukkan IconButton dengan icon List atau Folder */}
          </Header>
           
          <Box >
            <IconButton onClick={toggleView} color={color2}>
                {isGridView ? <List sx={{ fontSize: '3rem' }} /> : <Folder sx={{ fontSize: '3rem' }} />}
            </IconButton>

          </Box>
          </Box>
          <CssBaseline />
          {isGridView ? (
            <Grid container spacing={3}>
              {playlistData.map((video) => (
                <Grid item key={video?.id} xs={12} sm={6} md={4} lg={3}>
                  <Card
                      sx={{
                        borderRadius: '15px',
                        minWidth: 235,
                        margin: theme.spacing(1),
                        backgroundColor: colors.primary[400],
                        boxShadow: theme.palette.mode === 'dark' ? boxShadowStyle2 : boxShadowStyle,
                        transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
                        ':hover': {
                          transform: 'scale(1.02)',
                          backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
                        },
                      }}
>
                    <CardActionArea
                      component={Link}
                      to={`/detail-playlist/${encodeURIComponent(
                        video?.event_name
                      )}`}
                    >
                      <CardMedia
                        sx={{ height: 130, borderRadius:'15px' }}
                        component="img"
                        alt={`Thumbnail for ${video?.event_name}`}
                        image={video?.thumbnail_id}
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize:'15px',
                            fontWeight:'bold'
                          }}
                        >
                          {video?.event_name}
                        </Typography> 

                        <Box >
                          <Box display="flex" alignItems="center" >
                            <SubscriptionsTwoToneIcon fontSize="small"  />
                            
                            <Typography ml={2} variant="body2" component="span" sx={{paddingLeft:'5px'}}>
                            {totalVideo[video?.event_name] ? totalVideo[video?.event_name] : 0} Video</Typography>
                          </Box>
                        
                          
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <>
            <TableContainer  sx={{ maxHeight: 440, 
            boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',
          }}>
              <Table stickyHeader aria-label="sticky table">
              <TableHead >
                <TableRow>
                  <TableCell  sx={{background}}>
                    <Typography color={color} variant="h5" >
                    Video Thumbnail
                    </Typography>
                  </TableCell>
                  <TableCell align="left"  sx={{background}}>
                    <Typography color={color} variant="h5" >
                    Nama Playlist Video Event
                    </Typography>
                  </TableCell>
                  <TableCell align="left"  sx={{background}}>
                    <Typography color={color} variant="h5" >
                    Tanggal Mulai
                    </Typography>
                  </TableCell>
                  <TableCell align="left"  sx={{background}}>
                    <Typography color={color} variant="h5" >
                    Tanggal Selesai
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              
                <TableBody>
                  {getRowsOnPage().map((video) => (
                    
                    <TableRow key={video?.id}>
                      <TableCell>
                        <>
                            <Box component={Link}
                        to={`/detail-playlist/${encodeURIComponent(
                            video?.event_name
                        )}`}>

                            <img src={video?.thumbnail_id} alt="" style={{width:'100px', height:'60px'}}/>
                      </Box>
                        </>
                        </TableCell>
                      <TableCell sx={{fontSize:'15px'}}>{video?.event_name}</TableCell>
                      <TableCell sx={{fontSize:'15px'}}>{formatDateIndonesia(video?.startdate)}</TableCell>
                      <TableCell sx={{fontSize:'15px'}}>{formatDateIndonesia(video?.enddate)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
              <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={playlistData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default VideoPlaylist;
