// localStorageUtils.js

const USER_ID_KEY = 'user_id';

export const saveUserIdToLocalStorage = (userId) => {
  localStorage.setItem(USER_ID_KEY, userId);
};

export const getUserIdFromLocalStorage = () => {
  return localStorage.getItem(USER_ID_KEY);
};

export const clearUserIdFromLocalStorage = () => {
  localStorage.removeItem(USER_ID_KEY);
};
