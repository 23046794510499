import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";

import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';


import logoProfile from "../../assets/logo-erklika-biru.png";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],

      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState(null);


  return (
    <Box
      className={`${isCollapsed ? 'collapsed' : ''}`}
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
          // position: `fixed !important`,
          
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: theme.palette.mode === 'light' ? '#6870fa !important' : '#03dac5 !important',
        },
        // background-image: linear-gradient(to right, #6870fa, #747bfb, #8086fc, #8c91fd, #979cfe, #a3a5fc, #aeaff9, #b8b8f7, #c4c2f1, #cfccec, #d8d6e6, #e1e1e1);
        // background-image: linear-gradient(to right, #059789, #048b7f, #038074, #02756a, #016a60, #00605e, #00575a, #004d54, #004150, #073548, #11293e, #161e32);
        "& .pro-menu-item.active": {
          color: theme.palette.mode === 'light' ? '#0022FF !important' : '#03dac5 !important',

          // background: theme.palette.mode === 'light' ? '#E1E1E1' : '#161E32',
          background: theme.palette.mode === 'light' ? '#E1E1E1' : 'linear-gradient(to right, #059789, #048b7f, #038074, #02756a, #016a60, #00605e, #00575a, #004d54, #004150, #073548, #11293e, #161e32)',
          borderLeft: theme.palette.mode === 'light' ? '4px solid #6870fa' : '4px solid #03dac5',
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : ''}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography
                  variant="h4"
                  color={colors.grey[100]}
                  fontWeight="bold"
                >
                  Erklika Studio
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <div
                  style={{
                    width: 120, height: 120, backgroundColor: 'white', cursor: "pointer", borderRadius: "50%", display: "flex",
                    textOverflow: "ellipsis",
                    alignItems: "center",
                    justifyContent: "center",
                    outline: "none",
                  }}>
                  <img
                    alt="profile-user"
                    width="100px"
                    // height="20px"
                    src={logoProfile}
                    style={{ justifyContent: 'center' }}

                  />
                </div>
              </Box>
              <Box textAlign="center">
                <Typography variant="h5" 
                  sx={{ m: "10px 0 0 0", color: theme.palette.mode === 'light' ? '#6870fa !important' : '#03dac5 !important' }}>
                  Channel Anda
                </Typography>
                <Typography
                  variant="h4"
                  color={colors.grey[100]}
                  fontWeight="bold"
                >
                  Platform Digital 2
                </Typography>
              </Box>
            </Box>
          )}

          <Box>
            <Item
              title="Dashboard"
              to="/"
              icon={<DashboardOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              
            />
            
            <Item
              title="Analytics"
              to="/analytics"
              icon={<AnalyticsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data 
            </Typography> */}
            <Item
              title="Konten"
              to="/konten"
              icon={<VideoLibraryOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Komentar"
              to="/komentar"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="Invoices Balances"
              to="/invoices"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages 
            </Typography> */}
            {/* <Item
              title="Profile Form"
              to="/form"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

          
            <Item
              title="Subscriber"
              to="/subscriber"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          
            <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Playlist"
              to="/playlist"
              icon={<PlaylistPlayIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography> */}
            {/* <Item
              title="Bar Chart"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pie Chart"
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Chart"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Geography Chart"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected} 
            />*/}
          </Box>
        </Menu>
      </ProSidebar>
    </Box >
  );
};

export default Sidebar;
