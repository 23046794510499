import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Header from '../../components/Header';
import CopyToClipboard from 'react-copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Grid, TextField, IconButton, Button, Box, Select, MenuItem, FormControl, InputLabel, Alert, Snackbar, OutlinedInput, } from '@mui/material';
import axios from 'axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/system';
import ReactPlayer from 'react-player';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { useParams } from 'react-router-dom';

const Detail = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const [isCopied, setIsCopied] = useState(false);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);
  const [tagOptions, setTagsOptions] = useState([]);
  const [labelOptions, setLabelOptions] = useState([]);
  const [errors, setErrors] = useState({
    name_id: '',
    description_id: '',
    // thumbnail_id: '',
    publish: '',
  });

  const [data, setData] = useState({
    name_id: '',
    description_id: '',
    thumbnail_id: '',
    label_id: [],
    tag_id: [],
    publish: '',
  });

  const validationSchema = Yup.object().shape({
    name_id: Yup.string().required('Judul video harus diisi'),
    description_id: Yup.string().required('Deskripsi video harus diisi'),
    publish: Yup.string().required('Pilih visibilitas video'),
    // Add more validations for other fields as needed
  });
  

  const { id } = useParams();
  const baseUrl = process.env.REACT_APP_API_URL;

  //  API Get data by ID
  const fetchVideoDetail = async () => {
    try {
      const response = await fetch(`${baseUrl}/video/detailvideo/${id}`);
      const apiData = await response.json();
      if (response.ok) {
        setData(apiData.data[0]);
      } else {
        console.error('Error fetching data:', apiData.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // API Get labels
  const fetchLabels = async () => {
    try {
      const response = await fetch(`${baseUrl}/video/labels`);
      const apiData = await response.json();
      if (response.ok) {
        setLabelOptions(apiData.data);
        
      } else {
        console.error('Error fetching data:', apiData.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // API Get tags
  const fetchTags = async () => {
    try {
      const response = await fetch(`${baseUrl}/video/tags`);
      const apiData = await response.json();
      if (response.ok) {
        setTagsOptions(apiData.data);
      } else {
        console.error('Error fetching data:', apiData.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Load fetch api get
  useEffect(() => {
    fetchVideoDetail()
    fetchLabels()
    fetchTags()
  }, []);
  

  // fungsi Thumbnail Preview
  const handleThumbnailUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validasi jenis file gambar
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      if (!allowedTypes.includes(file.type)) {
        alert('Gambar harus dalam format JPEG, PNG, atau JPG');
        return;
      }

      // Validasi ukuran file
      if (file.size > 1024 * 1024 * 10) {
        alert('Gambar harus kurang dari 10MB');
        return;
      }

      setThumbnailPreview(file);
      setData((prevData) => ({
        ...prevData,
        thumbnail_id: file,
      }));
    }
  };

    // Fungsi text Input
  const handleChangeInput = (propertyName) => (e) => {
    setData(prevData => ({
      ...prevData,
      [propertyName]: e.target.value
    }));
  };

  // fungsi select input publish 
  const handleChange = (e) => {
    const value = e.target.value;
    setData((prevData) => ({
      ...prevData,
      publish: value,
    }));
  };
  
  // Fungsi multipleselect Tags
  const handleChangeSelect = (name, value) => {
    // Jika value bukan array, berarti hanya satu item yang dipilih
    console.log(value)
    const selectedValues = Array.isArray(value) ? value : [value];

    // const selectedLabelIds = value.map(item => item.label)
      
    // Mengambil nilai id dari item yang dipilih
    const selectedLabelIds = selectedValues.map(selectedLabel => selectedLabel.id).filter(Boolean); // Menyaring nilai yang tidak terdefinisi (undefined)
    console.log(selectedValues)
    
    setData((prevData) => ({
      ...prevData,
      [name]: selectedValues,
    }));
    setData((prevData) => ({
      ...prevData,
      tag_id: selectedLabelIds,
    }));
  };

  // Fungsi Multiple Select Label
  const handleChangeSelectlABEL = (name, value) => {
    console.log(value)
    // Jika value bukan array, berarti hanya satu item yang dipilih
    const selectedValues = Array.isArray(value) ? value : [value];

    // const selectedLabelIds = value.map(item => item.label)
      
    // Mengambil nilai id dari item yang dipilih
    const selectedLabelIds = selectedValues.map(selectedLabel => selectedLabel.id).filter(Boolean); // Menyaring nilai yang tidak terdefinisi (undefined)
    console.log(selectedLabelIds)
    setData((prevData) => ({
      ...prevData,
      [name]: selectedValues,
    }));
    setData((prevData) => ({
      ...prevData,
      label_id: selectedLabelIds,
    }));
  };
  
  // Fungsi Copy Nama File berupda string/Text
  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  // Validasi Input
  const validateInput = async () => {
    try {
      await validationSchema.validate(data, { abortEarly: false });
      return {}; // Mengembalikan objek kosong jika valid
    } catch (error) {
      // Mengembalikan pesan kesalahan sesuai dengan field
      return error.inner.reduce((errors, validationError) => {
        return {
          ...errors,
          [validationError.path]: validationError.message,
        };
      }, {});
    }
  };
  
  // Fungsi Button Simpan (POST DATA)
  const updateDataAction = async (e) => {
    try {
      e.preventDefault();
  
      const validationErrors = await validateInput();
  
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
  
      const formData = new FormData();
      console.log(data.label_id)
      formData.append('name_id', data.name_id);
      formData.append('description_id', data.description_id);
      formData.append('thumbnail_id', data.thumbnail_id);
      formData.append('publish', data.publish);
      formData.append('label_id', `[${data.label_id.join(', ')}]`); // Directly append label_id as a string
      formData.append('tag_id', `[${data.tag_id.join(', ')}]`); // Directly append label_id as a string

      formData.append("_method", "PUT");
  
      const response = await axios.post(`${baseUrl}/video/aam_lab/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 200) {
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate('/konten');
        }, 1000);
        console.log('Data berhasil diupdate:', data);
        console.log(JSON.stringify(data));
      } else {
        console.error('Error updating data:', response.statusText);
        alert('ini erro dari api brodi:', response.error);
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };
  

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  return (
    <>
      <Box m="20px">
        <Header title="Detail Content" subtitle="View and Edit Content video" />
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} variant="filled" severity="success">
            Video Berhasil Diupdate
          </Alert>
        </Snackbar>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      '& > div': { gridColumn: isNonMobile ? undefined : 'span 4', paddingTop: '10px' },
                    }}
                  >
                    <TextField
                      required
                      fullWidth
                      multiline
                      rows={4}
                      variant="filled"
                      type="text"
                      id="name_id"
                      label="Judul (wajib diisi)"
                      name="name_id"
                      value={data?.name_id || ''}
                      onChange={handleChangeInput('name_id')}
                      sx={{ gridColumn: 'span 4', paddingTop: '10px' }}
                    />

                    <TextField
                      required
                      fullWidth
                      multiline
                      rows={10}
                      variant="filled"
                      type="text"
                      id="description_id"
                      label="Deskripsi (wajib diisi)"
                      name="description_id"
                      value={data?.description_id || ''}
                      onChange={handleChangeInput('description_id')}
                      sx={{ gridColumn: 'span 4', paddingTop: '10px' }}
                    />
                   
                   
                   <Box sx={{ textAlign: 'center', width: '100%', border: '1px dashed grey', borderRadius: '5px', gridColumn: 'span 4', paddingTop: '10px' }}>
                    <Typography style={{ textAlign: 'left', marginLeft: '10px' }}>Upload Thumbnail</Typography>

                    <label htmlFor="thumbnail">
                      <IconButton component="span">
                        
                        {thumbnailPreview && (
                          <img
                            src={URL.createObjectURL(thumbnailPreview)}
                            alt="Thumbnail Preview"
                            style={{ maxWidth: '300px', maxHeight: '300px', marginBottom: '20px', borderRadius: '5px' }}
                          />
                        )}
                        {!thumbnailPreview && data?.thumbnail_id && (
                          <img
                            src={data.thumbnail_id}
                            alt=""
                            style={{ maxWidth: '300px', maxHeight: '300px', marginBottom: '20px', borderRadius: '5px' }}
                          />
                        )}
                        {!thumbnailPreview && data?.thumbnail_id == "https://192.168.40.38/erklika-cms-2/assets/upload_thumbnail/No Thumbnail available" && (
                          <CloudUploadIcon sx={{ fontSize: 100 }} />
                        )}
                      </IconButton>
                    </label>
                    <VisuallyHiddenInput
                      type="file"
                      id="thumbnail"
                      onChange={(e) => {
                        handleThumbnailUpload(e);
                      }}
                      name="thumbnail_id"
                      accept="image/*"
                    />
                  </Box>

                    <Button
                      type="button"
                      color="secondary"
                      variant="outlined"
                      sx={{mb:4}}
                      onClick={updateDataAction}
                    >
                      <IconButton color="inherit" size="small">
                        <EditIcon /> 
                      </IconButton>
                      Simpan 
                    </Button>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box
                    gap="20px"
                    sx={{
                      '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
                      marginTop: '10px',
                    }}
                  >
                    <Card fullWidth sx={{ gridColumn: "span 4" }}>
                      <CardMedia>                  
                          <ReactPlayer
                            width="400"
                            height="225"
                            controls
                            url={data?.filename_id}
                            onError={() => {
                              // Handle error when video cannot be played
                              console.error("Error playing video");
                            }}
                          />
                      </CardMedia>

                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          <Typography variant="body2" component="h5" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>Link Video</span>
                            <CopyToClipboard text={data?.filename_id || "no data displayed"} onCopy={handleCopy}>
                              <Button size="small" startIcon={<FileCopyIcon style={{ color: '#4CAF50' }} />}>
                                <span style={{ color: isCopied ? '#4CAF50' : 'grey' }}>
                                  {isCopied ? 'Copied!' : 'Copy Link'}
                                </span>
                              </Button>
                            </CopyToClipboard>
                          </Typography>
                          <Typography variant="h6">{data?.filename_id || "no data displayed"}</Typography>
                        </Typography>
                        <Typography gutterBottom variant="h5" component="div" sx={{ marginTop: '16px' }}>
                          <Typography variant="body2" component="h5">
                            <span>Nama File</span>
                          </Typography>
                          <Typography variant="h6">{data?.filename_id || "no data displayed"}</Typography>
                        </Typography>
                        <Typography gutterBottom variant="h5" component="div" sx={{ marginTop: '16px' }}>
                          <Typography variant="body2" component="h5">
                            <span>Kualitas</span>
                          </Typography>
                          <Typography variant="h6">SD</Typography>
                        </Typography>
                      </CardContent>
                    </Card>

                    <FormControl fullWidth rows={4}  sx={{ gridColumn: "span 4", paddingTop: '10px', mt:3 }}>
                      <InputLabel id="visibilitas-label" multiline >Visibility</InputLabel>
                      <Select
                        labelId="visibilitas-label"
                        id="visibilitas"
                        name="publish"
                        value={data?.publish}
                        onChange={handleChange}
                      >
                        <MenuItem value="1" >Public</MenuItem>
                        <MenuItem value="0">Private</MenuItem>
                      </Select>
                    </FormControl>

                    <MultipleSelectTags
                      tag="Tags"
                      tagOptions={tagOptions}
                      selectedValues={data.tags}
                      sx={{ gridColumn: 'span 4', mr: 1 }}
                      onChange={(selectedValues) => handleChangeSelect('tags', selectedValues)}
                    />           

                    <MultipleSelect
                      label="Label"
                      labelOptions={labelOptions}
                      selectedValues={data.label}
                      sx={{ gridColumn: 'span 4', mr: 1 }}
                      onChange={(selectedValues) => handleChangeSelectlABEL('label', selectedValues)}
                    />     
                  </Box>
                </Grid>
              </Grid>
      </Box>
    </>
  );
};

export default Detail;

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const MultipleSelectTags = ({ tag, tagOptions, selectedValues, onChange }) => {
  const selected = Array.isArray(selectedValues) ? selectedValues : [];

  return (
    <FormControl sx={{ width: '100%', mt: 4, paddingTop: '10px' }}>
      <InputLabel>{tag}</InputLabel> {/* Mengubah label menjadi tag */}
      <Select
        multiple
        value={selected}
        onChange={(e) => onChange(Array.isArray(e.target.value) ? e.target.value : [])}
        renderValue={(selected) => (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {selected.map((value) => (
              <Chip key={value.id} label={value.tags} style={{ margin: '2px' }} />
            ))}
          </div>
        )}
      >
        {tagOptions.map((option) => (
          <MenuItem key={option.id} value={option}>
            <Checkbox checked={selected.some(sel => sel.id === option.id)} />
            <ListItemText primary={option.tags} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// ini bisa 1 id yng terkirim namun tampilan tidak terlihat
const MultipleSelect = ({ label, labelOptions, selectedValues, onChange }) => {
  const selected = Array.isArray(selectedValues) ? selectedValues : [];

  return (
    <FormControl sx={{ width: '100%', mt: 4, paddingTop: '10px' }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={selected}
        onChange={(e) => onChange(Array.isArray(e.target.value) ? e.target.value : [])}
        renderValue={(selected) => (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {selected.map((value) => (
              <Chip key={value.id} label={value.label} style={{ margin: '2px' }} />
            ))}
          </div>
        )}
      >
        {labelOptions.map((option) => (
          <MenuItem key={option.id} value={option}>
            <Checkbox checked={selected.some(sel => sel.id === option.id)} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
