import { useState } from 'react';
import Chart from 'react-apexcharts';

const RadarChart = () => {
    const [chartData, setChartData] = useState({
        options: {
            chart: {
                type: 'radialBar',
                height: '100%'
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '16px',
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            formatter: function (w) {
                                // By default this function returns the average of all series. But we can customize it to display the sum.
                                let sum = 0;
                                for(let i = 0; i < w.globals.series.length; i++) {
                                    sum += w.globals.series[i];
                                }
                                return sum;
                            }
                        }
                    }
                }
            },
            labels: ['MTK', 'BIOLOGI', 'FISIKA', 'KIMIA', 'PYTHON'],
        },
        series: [20, 100, 40, 30, 50, ]
    });

    return (
        <div>
            <Chart options={chartData.options} series={chartData.series} type="radialBar"  />
        </div>
    );
};

export default RadarChart;


// import { useEffect, useState } from 'react';
// import axios from 'axios';
// import { ResponsiveBar } from '@nivo/bar';

// const BarChart = ({ apiUrl }) => {
//     const [chartData, setChartData] = useState([]);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await axios.get(apiUrl);
//                 const data = response.data.data.slice(0, 5).map(video => ({
//                     name: video.title.name_id,
//                     views: parseInt(video.views)
//                 }));
//                 setChartData(data);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         fetchData();
//     }, [apiUrl]);

//     return (
//         <div style={{ height: '200px' }}>
//             <ResponsiveBar
//                 data={chartData}
//                 keys={['views']}
//                 indexBy="name"
//                 // margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
//                 padding={0.3}
//                 colors={['#2196F3']}
//                 theme={{
//                     axis: {
//                         ticks: {
//                             text: { fill: '#ffffff' }
//                         }
//                     }
//                 }}
//                 layout="vertical" // Set layout to horizontal
//                 axisBottom={{
//                     tickRotation: -45,
//                     legendPosition: 'middle',
//                     legendOffset: 36
//                 }}
//                 axisLeft={{
//                     tickSize: 5,
//                     tickPadding: 5,
//                     legend: 'Views',
//                     legendPosition: 'middle',
//                     legendOffset: -40
//                 }}
//                 labelSkipWidth={12}
//                 labelSkipHeight={12}
//             />
//         </div>
//     );
// };

// export default BarChart;
