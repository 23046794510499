// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-add {
    border: 0;
    box-shadow: none !important
  }

  .card-header-add {
    border-bottom: 1px solid var(--vz-border-color)
  }

  .card-title-add {
    font-size: 16px;
    margin: 0 0 7px 0
  }

  .boxy {
    /* Mengatur transition untuk animasi smooth */
    transition: transform 1s ease-in-out;
  }
  
  .boxy:hover {
    /* Menghilangkan box shadow */
    /* box-shadow: none; */
    /* border: 0.5px solid #999793; */
    /* Mengatur transform scale agar box menjadi lebih besar */
    transform: scale(1.02);
    background-color: white;
    transition: transform 0.5s ease-in-out;
  }`, "",{"version":3,"sources":["webpack://./src/assets/css/custom.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IAET;EACF;;EAEA;IACE;EACF;;EAEA;IACE,eAAe;IACf;EACF;;EAEA;IACE,6CAA6C;IAC7C,oCAAoC;EACtC;;EAEA;IACE,6BAA6B;IAC7B,sBAAsB;IACtB,iCAAiC;IACjC,0DAA0D;IAC1D,sBAAsB;IACtB,uBAAuB;IACvB,sCAAsC;EACxC","sourcesContent":[".card-add {\r\n    border: 0;\r\n    -webkit-box-shadow: none !important;\r\n    box-shadow: none !important\r\n  }\r\n\r\n  .card-header-add {\r\n    border-bottom: 1px solid var(--vz-border-color)\r\n  }\r\n\r\n  .card-title-add {\r\n    font-size: 16px;\r\n    margin: 0 0 7px 0\r\n  }\r\n\r\n  .boxy {\r\n    /* Mengatur transition untuk animasi smooth */\r\n    transition: transform 1s ease-in-out;\r\n  }\r\n  \r\n  .boxy:hover {\r\n    /* Menghilangkan box shadow */\r\n    /* box-shadow: none; */\r\n    /* border: 0.5px solid #999793; */\r\n    /* Mengatur transform scale agar box menjadi lebih besar */\r\n    transform: scale(1.02);\r\n    background-color: white;\r\n    transition: transform 0.5s ease-in-out;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
