import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";

const StatBox = ({ title, subtitle, icon, progress }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // Definisikan warna untuk mode light
  const lightColors = {
    backgroundImage:'linear-gradient(to right, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff)'

  };
  // const lightColors = {
  //   backgroundImage: 'linear-gradient(to right, #2102f6, #072bfa, #0040fd, #0051ff, #005fff, #2e69fc, #4573f9, #567cf5, #6b84ed, #7d8de6, #8b95de, #989ed6);'
  // };
  
  // Definisikan warna untuk mode dark
  const darkColors = {
    backgroundImage:'linear-gradient(to right, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff)'

  };
  // const darkColors = {
  //   backgroundImage:'linear-gradient(to right, #f6f544, #f7f64f, #f7f658, #f8f761, #f8f76a, #f7f672, #f7f679, #f6f580, #f4f488, #f3f38f, #f1f196, #f0f09d);',
  // };
  
  const color = theme.palette.mode === 'dark' ? '#03dac5' : '#0C008B';
  

  return (
    <Box width="100%" m="0 30px">
      <Box display="flex" justifyContent="space-between">
        <Box>
          {icon}
         
        </Box>
        <Box>
          <ProgressCircle progress={progress} />
          
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
      <Typography variant="h5" 
      sx={{
        backgroundImage: theme.palette.mode === 'light' ? lightColors.backgroundImage : darkColors.backgroundImage,
       WebkitBackgroundClip: 'text', 
       color: 'transparent', 
       fontWeight: 'bold', 
       display: 'inline-block' 
       }}>
  {subtitle}
</Typography>

<Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color:'#fffffF' }}
          >
            {title}
          </Typography>
      </Box>
    </Box>
  );
};

export default StatBox;
