import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import { Typography, Box, Container, CssBaseline, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Snackbar, Alert } from '@mui/material';
const QRLogin = () => {
  
  
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);


// Menampilkan alamat IP pengguna di konsol
  useEffect(() => {
    // Generate token pertama kali saat komponen dimuat
    generateQRToken();

    // Interval untuk mengubah token setiap 60 detik
    const interval = setInterval(() => {
      generateQRToken();
    }, 600000);

    // Membersihkan interval saat komponen dilepas
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Memeriksa validitas sesi saat token berubah
    if (token !== "") {
      const interval = setInterval(() => {
        checkSessionValidity();
      },20000);

      // Membersihkan interval saat token berubah
      return () => clearInterval(interval);
    }
  }, [token]);


  const generateQRToken = () => {
    const currentDate = new Date();
    const qrToken = currentDate.toISOString();
    const currentDates = new Date(qrToken);
    const uniqueCode = 'erklika';

    const year = currentDates.getFullYear();
    const month = String(currentDates.getMonth() + 1).padStart(2, '0');
    const day = String(currentDates.getDate()).padStart(2, '0');
    const hours = String(currentDates.getHours()).padStart(2, '0');
    const minutes = String(currentDates.getMinutes()).padStart(2, '0');
    const seconds = String(currentDates.getSeconds()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const browserAgent = navigator.userAgent;
    // const browserAgent = 'erklika';
    const browserName = navigator.userAgent.split(' ')[0];
    console.log('Nama browser:', browserName);
    // Menggabungkan QR Token dan Unique Code
    const combinedToken = formattedDateTime + '#' + browserName + '#' +  browserAgent +  uniqueCode;
    
    // Mengenkripsi token yang telah digabung
    const encodedCombinedToken = btoa(combinedToken);
    
    // Lakukan sesuatu dengan encodedCombinedToken
    setToken(encodedCombinedToken); // Mengenkripsi token sebelum diset
    console.log(atob(encodedCombinedToken));
    // console.log(qrToken);
};

const extractFrontendToken = (encodedToken) => {
  // Mencari indeks karakter '=='
  const separatorIndex = encodedToken.indexOf('==');
  if (separatorIndex !== -1) {
    // Memisahkan token setelah '=='
    return encodedToken.substring(0, separatorIndex + 2);
  }
  return encodedToken;
};


  const encodeToken = (token) => {
    return btoa(token); // Menggunakan btoa() untuk mengenkripsi token
  };

  // const userId = "9767";
  // const userId = localStorage.getItem('user_id');

  const checkSessionValidity = async () => {
    try {

        // const tokenAtob= atob(token);
        // const hashIndex = tokenAtob.indexOf('#');
        // // Jika '#' ditemukan, ambil substring sebelumnya, jika tidak, gunakan string aslinya
        // const outputString = hashIndex !== -1 ? tokenAtob.substring(0, hashIndex) : tokenAtob;

        // const tokenBtoa = btoa(outputString);
        const formdata = new FormData();
        formdata.append("token_contributor", token);

        const requestOptions = {
            method: "POST",
            body: formdata,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/cekToken`, requestOptions);
        const data = await response.json(); // Ubah respons menjadi JSON

        if (response.ok) {
            const { token_contributor, user_id, device_id, app_key, md, browser_id, status_log } = data; // Ekstrak data yang diperlukan dari respons API
            // console.log(`token: ${token}, token Api${tokenApi}`)
            // Bandingkan token yang diterima dari API dengan token yang digenerate
            if (token_contributor === token) {
                // Jika token valid, simpan data dan arahkan ke halaman utama
                localStorage.setItem("user_id", user_id);
                localStorage.setItem("token_contributor", token_contributor);
                localStorage.setItem("device_id", device_id);
                localStorage.setItem("app_key", app_key);
                localStorage.setItem("md", md);
                localStorage.setItem("browser_id", browser_id);
                localStorage.setItem("status_log", status_log);
                navigate('/');
            } else {
                console.log("Login gagal! Token tidak sama.");
            }
        } else {
            console.log("Login gagal! Token tidak valid.");
        }
    } catch (error) {
        console.error("Error checking session validity:", error);
    }
};



  return (
    <Box 
      // component="main" 
      maxWidth="lg" 
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      sx={{
        backgroundImage: 'url(https://erklika.id/images/bg/bg-erklika.jpg)',
        backgroundPosition: 'center center',
        minWidth: '100vw',
        backgroundSize: 'fit',
    
      }}>
      {/* <CssBaseline /> */}

      {/* Box Login */}
      <Box
     display="flex"
     // alignItems="center"
     justifyContent="space-around"
     sx={{
       maxWidth:'900px',
       // padding:'20px',
     margin:' auto',
     // height:'100vh', 
     backgroundColor: 'rgba(0, 0, 0, 0.7)', // Adjust the alpha value for transparency
     backdropFilter: 'blur(8px)', // Set the blur amount
     borderRadius:'10px'
     }}
>
<Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} variant="filled" severity="success">
            Video Berhasil Diupload
          </Alert>
        </Snackbar>
   <Box sx={{width:'40%'}}>
     <Box sx={{display:'flex', justifyContent:'center'}}>
       <img src={`${process.env.PUBLIC_URL}/erklika_kontributor.png`} alt="" width="350" style={{backgroundColor:'none',  border:'none', borderRadius:'8px', marginBottom:'20px' }}/>

     </Box>
     <Typography variant="h4" mb={2}>
       Untuk mendapatkan akses sebagai Kontributor Erklika, ikuti langkah-langkah berikut ini:
     </Typography>
     <Typography variant="h6" mb={2}>
       1. Buka aplikasi Erklika pada telepon Anda.
     </Typography>
     <Typography variant="h6" mb={2}>
       2. Ketuk [ikon akun] di menu utama.
     </Typography>
     <Typography variant="h6" mb={2}>
       3. Pilih opsi "Scan Kode QR".
     </Typography>
     <Typography variant="h6" mb={2}>
       4. Arahkan telepon Anda ke layar ini untuk memindai kode tersebut.
     </Typography>
    
   </Box>
   <Box sx={{width:'20%'}}>
     
     {token && (
       <>
     <Typography variant="h6" mb={2} mt={3} sx={{textAlign:'center'}}>
      Scan QR Disini
     </Typography>
         <Box sx={{ display: 'flex', background: 'white' , padding:'10px' , width: '230px',position: "relative"}}>
           <QRCode sx={{height:'100%', mr:3 }} value={token} />
           <img
          src={`${process.env.PUBLIC_URL}/erklikaBlack.png`}
          alt="Logo"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40px",
            height: "40px",
            borderRadius: "10px",
            background:'white'
          }}/>
         </Box>
         <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', ml:3,  wordWrap: 'break-word' }}>
           <Typography variant="body1" mt={2} fontWeight="bold">
             Token:
           </Typography>
           <Typography variant="body1" fontWeight="bold" sx={{fontSize:'10px !important'}} >
             {token}
           </Typography>
         </Box>
        

       </>
     )}
   </Box>
</Box> 
    </Box>
  );
};

export default QRLogin;

 
