import { Alert, Snackbar, Box, Button, IconButton, useTheme, Fade, Backdrop, Typography,  Menu, MenuItem  } from "@mui/material";
import { useContext } from "react";
import * as React from 'react';
import { useState } from 'react';
import { ColorModeContext, tokens } from "../../theme";
import { styled } from '@mui/material/styles';
// import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CircularProgressWithLabel from "../../components/IndicatorUpload"; // Sesuaikan path sesuai dengan struktur proyek Anda
import ReactPlayer from 'react-player';

import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import VideoCallIcon from '@mui/icons-material/VideoCall';
import Modal from '@mui/material/Modal';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useNavigate } from 'react-router-dom';

//import SearchIcon from "@mui/icons-material/Search";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: '#282828',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};
const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_URL;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [videoPreview, setVideoPreview] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  

  const handleProfileMenuOpen = (event) => {
    setProfileMenuAnchor(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileMenuAnchor(null);
  };

  const userId = localStorage.getItem('user_id');
  const handleProfileClick = () => {
    // Implementasi navigasi ke halaman profil akun
   navigate(`/profile/${userId}`);
    handleProfileMenuClose();
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(`${baseUrl}/auth/signout/${userId}`, {
        method: 'POST',
        // Include any necessary headers or credentials for your API request
      });

      if (response.ok) {
        setSnackbarOpen(true);
        localStorage.removeItem('user_id');
        localStorage.removeItem('device_id');
        localStorage.removeItem('app_key');
        setTimeout(() => {
          navigate('/instant-login');
        }, 2000); 
      } else {
        // Handle errors or display appropriate messages
        console.error('Logout failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  // upload Video
  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];
  
    if (file) {

      const maxSizeInBytes = 2 * 1024 * 1024 * 1024; // 2GB

      if (file.size > maxSizeInBytes) {
        alert("Ukuran file video terlalu besar. Maksimal 2GB.");
        e.target.value = null;
        setVideoPreview(null);
        return;
      }
  
      const videoURL = URL.createObjectURL(file);
      setVideoPreview(videoURL);

      const formData = new FormData();
      formData.append('filename_id', file);
  
      const userId = localStorage.getItem('user_id');
  
      if (userId) {
        formData.append('createdBy', userId);
  
        try {
          setIsUploading(true);
  
          const xhr = new XMLHttpRequest();
          xhr.open('POST', `${baseUrl}/video/add`, true);
  
          xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
              const progress = (event.loaded / event.total) * 100;
              setUploadProgress(progress);
            }
          };
  
          xhr.onload = () => {
            if (xhr.status === 200) {
              // Video berhasil diupload, lanjutkan ke halaman berikutnya
              const responseData = JSON.parse(xhr.responseText);
              
                setSnackbarOpen(true);
          
                setTimeout(() => {
                  setSnackbarOpen(false);
                  navigate(`/konten-detail/${responseData.id}`);
                }, 5000);
            
             
            } else {
              const errorData = JSON.parse(xhr.responseText);
              alert(`Error: ${errorData.message}`);
            }
            setIsUploading(false);
          };
  
          xhr.onerror = (error) => {
            console.error('Error uploading video:', error);
            alert(`Error: ${error.message}`);
            setIsUploading(false);
          };
  
          xhr.send(formData);
        } catch (error) {
          console.error('Error uploading video:', error);
          alert(`Error: ${error.message}`);
          setIsUploading(false);
        }
      } else {
        alert('Gagal mendapatkan user_id dari local storage.');
      }
    }
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          {/* <SearchIcon /> */}
        {/* </IconButton> */}
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        
        <IconButton onClick={handleOpen}>
          <VideoCallIcon />
        </IconButton>
        {/* <Button variant="contained" startIcon={<VideoCallIcon />} onClick={handleOpen}>
          BUAT
        </Button> */}
         <IconButton onClick={handleProfileMenuOpen}>
          <PersonOutlinedIcon />
        </IconButton>
        
        <Menu
          id="profile-menu"
          anchorEl={profileMenuAnchor}
          open={Boolean(profileMenuAnchor)}
          onClose={handleProfileMenuClose}
        >
          <MenuItem onClick={handleProfileClick}>Profil Akun</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>

      </Box>


      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        
      >
        <Fade in={open}>
          <Box sx={style }>
              
            <Typography id="transition-modal-title" variant="h2" component="h2" sx={{ fontWeight: 'bold', mt: 2, textAlign: 'center' }} color={colors.greenAccent[500]}>
              Upload video
            </Typography>
            <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} variant="filled" severity="success">
            Video Berhasil Diupload
          </Alert>
        </Snackbar>

            <Box sx={{ mt: 5, textAlign: 'center' }} >

            <label  htmlFor="filename_id">
               
                    <IconButton component="span">
                      {videoPreview ? (
                        <ReactPlayer
                          width="150"
                          height="100"
                          url={videoPreview}
                          controls
                          sx={{borderRadius:'10px'}}
                        />
                      ) : (
                        <CloudUploadIcon sx={{ fontSize: 120 }} />
                      )}
                    </IconButton>
                  </label>
                  <input
                    type="file"
                    id="filename_id"
                    onChange={(e) => {
                      handleVideoUpload(e);
                    }}
                    name="filename_id"
                    accept="video/*"
                    style={{ display: 'none' }}
                  />
           
            </Box>
                  {isUploading && (
                    <>
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background:'none', borderRadius: '8px', padding: '20px' }}>
                      <img style={{borderRadius:'50px'}} src={`${process.env.PUBLIC_URL}/wait.gif`} alt="Loading GIF" width="50" />
                    </div>
                    <CircularProgressWithLabel value={uploadProgress} />
                    </>
                  )}
                
            <Typography id="transition-modal-description" sx={{ mt: 3, textAlign: 'center' }}>
              Input file video 
            </Typography>
            <Typography id="transition-modal-description" sx={{ textAlign: 'center' }}>
              Video Anda akan bersifat private sampai Anda memublikasikannya
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </Box >
  );
};

export default Topbar;

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


