import { Alert, Snackbar, Box, Button, IconButton, useTheme, Fade, Backdrop, Typography,  Menu, MenuItem  } from "@mui/material";
import { useContext } from "react";
import * as React from 'react';
import { useState } from 'react';
import { ColorModeContext, tokens } from "../../theme";
import { styled } from '@mui/material/styles';



const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
 

  return (
    <Box backgroundColor={colors.primary[400]} mt={5}  >

      {/* ICONS */}
      <Box display="flex" justifyContent="space-between" p={2} >
        <Typography>© Erlangga Digital, Inc, 2024. Copyright</Typography>
        <Typography>Erklika Kontributor - 2024</Typography>
        
        
       

      </Box>


    </Box >
  );
};

export default Topbar;

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


