// authService.js

// Fungsi untuk mengecek apakah pengguna sudah login
const isLoggedIn = () => {
    // Memeriksa apakah ada item 'user_id', 'app_key', dan 'device_id' di localStorage
    const userId = localStorage.getItem('user_id');
    const appKey = localStorage.getItem('app_key');
    const deviceId = localStorage.getItem('device_id');

    // Mengembalikan true jika semua item ditemukan, jika tidak kembalikan false
    return !!userId && !!appKey && !!deviceId;
};

export { isLoggedIn };
