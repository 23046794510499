import { Modal, Box, Typography, TextField, Button,Snackbar,
  Alert, IconButton, Fade, Backdrop, useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Zoom } from "react-reveal";
import Header from "../../components/Header";
import CircularProgressWithLabel from "../../components/IndicatorUpload"; // Sesuaikan path sesuai dengan struktur proyek Anda
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import ReactPlayer from 'react-player';
import EditIcon from '@mui/icons-material/Edit';
import { LinearProgress } from '@mui/material';
import { getUserIdFromLocalStorage } from '../../utils/lc';
import { saveUserIdToLocalStorage } from '../../utils/lc';
import { fetchUserProfile } from '../../utils/fetchUserId';








const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Konten = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: theme.palette.mode === 'light' ? '#F0F0F0' : '#282828',
    border: `2px solid ${colors.greenAccent[500]}`,
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
  };
  const navigate = useNavigate();
  const [allContents, setAllContents] = useState([]);
  const [open, setOpen] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [videoPreview, setVideoPreview] = useState(null);
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [data, setData] = useState({
    filename_id: '',
  });
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [dragOver, setDragOver] = useState(false);
  const [droppedFile, setDroppedFile] = useState(null);
  const baseUrl = process.env.REACT_APP_API_URL;

  const fetchData = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      };

      const fData = await fetch(`${baseUrl}/video/list`, requestOptions);
      const restData = await fData.json();
      setAllContents(restData.data);
    } catch (error) {
      throw error;
    }
  };

  // fetch user_id from localstorag

  useEffect(() => {
   
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
    fetchData();
  }, [baseUrl]);

  const columns = [
    
    {
      field: "title",
      headerName: "Video",
      flex: 2,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center',  }}>
          <img src={params.value?.thumbnail_id} alt="Video Thumbnail" style={{ height: '60px', marginRight: '8px', width:'100px', backgroundSize:'cover', marginTop:'4px', marginBottom:'4px' }} />
          {params.value?.name_id}
        </div>
      ),
    },
    {
      field: "visibilitas",
      headerName: "Visibilitas",
      type: "string",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row?.visibilitas || "There is no data",
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {params.row?.visibilitas === "Publish" ? (
            <PublicIcon color= {colors.blueAccent[100]} />
          ) : params.row?.visibilitas === "Not Publish" ? (
            <LockIcon  />
          ) : null}
          <span style={{ marginLeft: '4px' }}>{params.row?.visibilitas === "Publish" ? "Public" : "Private"}</span>
        </div>
      ),
    },
    {
      field: "date",
      headerName: "Tanggal",
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueGetter: (params) => {
        const date = params.row?.date;
    
        if (date) {
          const formattedDate = new Date(date).toLocaleDateString("id-ID", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
          return formattedDate;
        }
    
        return "There is no data";
      },
    },    
    {
      field: "penayangan",
      headerName: "Penayangan",
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueGetter: (params) => params.row?.penayangan || "There is no data",
    },
    {
      field: "suka",
      headerName: "Suka (vs.tidak suka)",
      flex: 1,
      valueGetter: (params) => params.row?.suka || "There is no data",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div style={{display:'flex', justifyContent:'space-around'}}>
          {/* Tombol Detail */}
          <Button  size="small" variant="outlined" color="secondary" onClick={() => handleDetail(params.row.id)} sx={{mr:2}}>
            <EditIcon/>
            
          </Button>
  
          {/* Tombol Delete */}
          <Button  size="small" variant="outlined" color="error" onClick={() => handleDelete(params.row.id)} disabled={true}>
           <DeleteIcon/>
           
          </Button>
        </div>
      ),
    },
  ];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Drag & Drop
  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragOver(true);
  };
  
  const handleDragLeave = () => {
    setDragOver(false);
  };
  
  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy'; // Memberitahu browser bahwa file dapat di-drop
    setDragOver(true);
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
  
    const file = e.dataTransfer.files[0];
    if (file) {
      setDroppedFile(file);
    }
  };
  
   // Fungsi untuk menangani klik tombol Detail
  const handleDetail = (id) => {
  // Lakukan sesuatu, misalnya navigasi ke halaman detail dengan menggunakan id
  navigate(`/konten-detail/${id}`);
  };

  const handleDelete = (id) => {
    // Lakukan sesuatu, misalnya munculkan konfirmasi penghapusan
    console.log(`Menghapus item dengan ID: ${id}`);
    // Implementasikan logika penghapusan atau tampilkan dialog konfirmasi penghapusan
    
  };

  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];
  
    if (file) {

      const maxSizeInBytes = 2 * 1024 * 1024 * 1024; // 2GB

      if (file.size > maxSizeInBytes) {
        alert("Ukuran file video terlalu besar. Maksimal 2GB.");
        e.target.value = null;
        setVideoPreview(null);
        return;
      }
  
      const videoURL = URL.createObjectURL(file);
      setVideoPreview(videoURL);

      const formData = new FormData();
      formData.append('filename_id', file);
  
      const userId = localStorage.getItem('user_id');
  
      if (userId) {
        formData.append('createdBy', userId);
  
        try {
          setIsUploading(true);
  
          const xhr = new XMLHttpRequest();
          xhr.open('POST', `${baseUrl}/video/add`, true);
  
          xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
              const progress = (event.loaded / event.total) * 100;
              setUploadProgress(progress);
            }
          };
  
          xhr.onload = () => {
            if (xhr.status === 200) {
              // Video berhasil diupload, lanjutkan ke halaman berikutnya
              const responseData = JSON.parse(xhr.responseText);
              
                setSnackbarOpen(true);
          
                setTimeout(() => {
                  setSnackbarOpen(false);
                  navigate(`/konten-detail/${responseData.id}`);
                }, 5000);
            
             
            } else {
              const errorData = JSON.parse(xhr.responseText);
              alert(`Error: ${errorData.message}`);
            }
            setIsUploading(false);
          };
  
          xhr.onerror = (error) => {
            console.error('Error uploading video:', error);
            alert(`Error: ${error.message}`);
            setIsUploading(false);
          };
  
          xhr.send(formData);
        } catch (error) {
          console.error('Error uploading video:', error);
          alert(`Error: ${error.message}`);
          setIsUploading(false);
        }
      } else {
        alert('Gagal mendapatkan user_id dari local storage.');
      }
    }
  };

  const [userData, setUserData] = useState(null);

  const tokenss = localStorage.getItem('token_contributor');
  const md = localStorage.getItem('md');

  const browser_id = localStorage.getItem('browser_id');
  const userId = localStorage.getItem('user_id');
  const device_id = localStorage.getItem('device_id');
  const app_key = localStorage.getItem('app_key');

  useEffect(() => {
  
      // Panggil API untuk memeriksa status login
      fetchDataFromApi();
  }, []); // Jadikan array dependencies kosong agar useEffect hanya dijalankan sekali saat komponen dimount
  
  const fetchDataFromApi = async () => {
    try {

      const formdata = new FormData();
      // formdata.append("token_contributor",tokenss );
      formdata.append("id", userId );
      // formdata.append("md", md );
      // formdata.append("device_id", device_id);
      // formdata.append("app_key", app_key);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };

      const response = await fetch(`${baseUrl}/auth/check/`, requestOptions);
      const result = await response.json();
      console.log(result);
      setUserData(result); ;

      

      // Jika ditemukan user dengan status_log "online" yang memiliki user_id yang sama, tampilkan pop-up deteksi
      // if (result.status_log === userId && result.md !== md) {
      if (result?.user_id === userId && result?.status_log === "offline") {
        handleLogout();
        // setOpenPopup(true);
      }
        console.log(response.data);// Simpan data respons API ke state
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const handleLogout = async () => {
      try {
        
        const response = await fetch(`${baseUrl}/auth/signout/${userId}`, {
          method: 'POST',
          // Include any necessary headers or credentials for your API request
        });
  
        if (response.ok) {
          localStorage.removeItem('user_id');
          localStorage.removeItem('device_id');
          localStorage.removeItem('browser_id');
          localStorage.removeItem('app_key');
          localStorage.removeItem('md');
          localStorage.removeItem('token_contributor');
          setTimeout(() => {
            navigate('/login');
          }, 2000); 
        } else {
          // Handle errors or display appropriate messages
          console.error('Logout failed:', response.statusText);
        }
      } catch (error) {
        console.error('Error during logout:', error);
      }
    };
  
  return (
    <Box m="0 20px" >
        
      <Header title="Konten channel" subtitle="List of Content Channel" />
      <div>
      <Button
      type="submit"
      variant="outlined"
      color="bloodymary"
      onClick={handleOpen}
      sx={{
        boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',

      }}
    >
      <IconButton color="bloodymary" size="small">
        <AddCircleOutlineIcon />
      </IconButton>
      New Content
    </Button>
      </div>

      <Zoom left>
        <Box
          m="20px 0 0 0"
          height="75vh"
          sx={{
              padding: '5px 0',  // Tambahkan padding top dan bottom di sini
              "& .MuiDataGrid-root": {
              },
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
            
              "& .MuiDataGrid-columnHeaders": {
                background: theme.palette.mode === 'dark' 
                ? 'linear-gradient(135deg, #ffffff, #03dac5, #32b3a8)' 
                : '#6870FA',
                color: theme.palette.mode === 'dark' ? '#000000' : '#ffffff',
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                background: theme.palette.mode === 'dark' 
                ? 'linear-gradient(135deg, #ffffff, #03dac5, #32b3a8)' 
                : '#6870FA',
                color: theme.palette.mode === 'dark' ? '#000000' : '#ffffff',
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
          
                
            

          }}
        >
          {isLoading ? (
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background:'none', borderRadius: '8px', padding: '20px' }}>
              {/* <img src={`${process.env.PUBLIC_URL}/video-player.gif`} alt="Loading GIF" width="50" /> */}
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <DataGrid checkboxSelection rows={allContents} columns={columns}  sx={{
              borderRadius: '5px !important',
              boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',

              marginLeft: '5px'
            }} />
          )}
        </Box>
      </Zoom>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        
      >
        <Fade in={open}>
          <Box sx={style }>
              
            <Typography id="transition-modal-title" variant="h2" component="h2" sx={{ fontWeight: 'bold', mt: 2, textAlign: 'center' }} color={colors.greenAccent[500]}>
              Upload video
            </Typography>
            <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} variant="filled" severity="success">
            Video Berhasil Diupload
          </Alert>
        </Snackbar>

            <Box sx={{ mt: 5, textAlign: 'center' }} >

            <label  htmlFor="filename_id"
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}>
                    <IconButton component="span">
                      {videoPreview ? (
                        <ReactPlayer
                          width="150"
                          height="100"
                          url={videoPreview}
                          controls
                          sx={{borderRadius:'10px'}}
                        />
                      ) : (
                        <CloudUploadIcon sx={{ fontSize: 120 }} />
                      )}
                    </IconButton>
                  </label>
                  <input
                    type="file"
                    id="filename_id"
                    onChange={(e) => {
                      handleVideoUpload(e);
                    }}
                    name="filename_id"
                    accept="video/*"
                    style={{ display: 'none' }}
                  />
           
            </Box>
                  {isUploading && (
                    <>
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background:'none', borderRadius: '8px', padding: '20px' }}>
                      <img style={{borderRadius:'50px'}} src={`${process.env.PUBLIC_URL}/wait.gif`} alt="Loading GIF" width="50" />
                    </div>
                    <CircularProgressWithLabel value={uploadProgress} />
                    </>
                  )}
                
            <Typography id="transition-modal-description" sx={{ mt: 3, textAlign: 'center' }}>
              Input file video 
            </Typography>
            <Typography id="transition-modal-description" sx={{ textAlign: 'center' }}>
              Video Anda akan bersifat private sampai Anda memublikasikannya
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default Konten;
