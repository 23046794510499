import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Fade } from "react-reveal";
import axios from 'axios';
import { Box, Button, IconButton, Icon, Typography, useTheme, Tab, Tabs, AppBar, Modal } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MobileStepper from '@mui/material/MobileStepper';
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import TrendingUp from "@mui/icons-material/TrendingUp";
import TrendingDown from "@mui/icons-material/TrendingDown";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Header from "../../components/Header";
import LineChartPenonton from "../../components/LineChartPenonton";


interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TabsUtama() {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ 
        borderBottom: 1, 
        borderColor: 'divider' , 
        
    
    }}>

        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          sx={{
            
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.mode === 'light' ? '#6870fa' : '#03dac5',
            },
            "& .Mui-selected": {
              color: theme.palette.mode === 'light' ? '#6870fa' : '#03dac5',
            },
            
          }}
          aria-label="secondary tabs example"
        >
          <Tab label="Ringkasan" {...a11yProps(0)} style={{ color: colors.grey[200] }} />
          <Tab label="Konten" {...a11yProps(1)} style={{ color: colors.grey[200] }} />
          <Tab label="Penonton" {...a11yProps(2)} style={{ color: colors.grey[200] }} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <RingkasanTab sx={{}}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <KontenTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <PenontonTab />
      </CustomTabPanel>
    </Box>
  );
}

/////RingkasanTab\\\\
function RingkasanTab() {

  const [kontenPopuler, setKontenPopuler] = useState([]);
  const [lastWatchChannel, setLastWatchChannel] = useState('');
  const [allPenayangan, setAllPenayangan] = useState('');
  const defaultImage = `${process.env.PUBLIC_URL}/logo-erklika-biru.png`;
  // api Url Access
  const baseUrl = process.env.REACT_APP_API_URL;
  const userId = localStorage.getItem("user_id");
  const apiAnalyticPenayangan = `${baseUrl}/chart/penayangan`;
  // api count All penayangan
  useEffect(() => {
    const allWatch = async () => {
      try {
        const response = await axios.get(`${baseUrl}/video/count_all_watch/${userId}`);
        const { data } = response.data;
        // Karena respons API berisi array data, Anda perlu mengakses elemen pertama dari array tersebut
        const allWatchData = data[0];
        // Kemudian, Anda dapat mengambil nilai last_watch_chanel dari objek tersebut
        const allWatchChannel = allWatchData.count_all_watch;
        setAllPenayangan(allWatchChannel);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    allWatch();
  }, []);
  // api konten populer
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/video/periodvideopop`);
        setKontenPopuler(response.data.data);
      } catch (error) {
        throw error;
      }
    };

    const lastWatch = async () => {
      try {
        const response = await axios.get(`${baseUrl}/video/watch_last_30_days/${userId}`);
        const { data } = response.data;
        setLastWatchChannel(data.last_watch_chanel);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    lastWatch();
  }, []);
  
  // api berapa kali ditonton dalm sebulan
  useEffect(() => {
    const lastWatch = async () => {
      try {
        const response = await axios.get(`${baseUrl}/video/watch_last_30_days/${userId}`);
        const { data } = response.data;
        // Karena respons API berisi array data, Anda perlu mengakses elemen pertama dari array tersebut
        const lastWatchData = data[0];
        // Kemudian, Anda dapat mengambil nilai last_watch_chanel dari objek tersebut
        const lastWatchChannel = lastWatchData.last_watch_chanel;
        setLastWatchChannel(lastWatchChannel);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    lastWatch();
  }, []);
  

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const background = theme.palette.mode === 'dark' ? 'linear-gradient(135deg, #ffffff, #03dac5, #32b3a8)' : 'linear-gradient(135deg, #ffffff, #6dd5fa, #2980b9)';
  const color = theme.palette.mode === 'dark' ? '#000000' : '#ffffff';
  const background = theme.palette.mode === 'dark' 
  ? 'linear-gradient(135deg, #ffffff, #03dac5, #32b3a8)' 
  : '#6870FA';
  return (
    <>
        <Fade up >
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="140px"
              gap="20px"
              
            >

              <Box
                gridColumn="span 8"
                gridRow="span 6"
                backgroundColor={colors.primary[400]}
                sx={{
                boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',
                borderRadius: '8px',
                transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
                ':hover': {
                  transform: 'scale(1.02)',
                  backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
                },
                }}
              >

                <div style={{ padding: '0 10px' }}>
                  <Typography color={colors.grey[100]} variant="h3" fontWeight="600" p={3} style={{ textAlign: 'center' }}>
                    Channel Anda ditonton {lastWatchChannel} kali dalam 30 hari terakhir

                  </Typography>
                </div>

                <FullRingkasanTab />


                <div style={{ padding: '0 10px' }}>
                  <Typography color={colors.grey[100]} variant="h3" fontWeight="600" p={3} style={{ textAlign: 'center' }}>
                    Konten populer Anda selama periode ini
                  </Typography>
                </div>

                <div style={{ padding: '0 10px' }}>
                  <TableContainer component={Paper} style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                    <Table aria-label="simple table">
                      <TableHead sx={{background}}>
                        <TableRow>
                        
                          <TableCell align="left">
                            <Typography variant="h5" fontWeight="600"  color={color}>
                              Konten
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="h5" fontWeight="600"  color={color} sx={{ whiteSpace: 'nowrap' }}>
                              Rata-rata durasi tonton
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="h5" fontWeight="600"  color={color}>
                              Penayangan
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {kontenPopuler && kontenPopuler.map((row) => (
                          <TableRow
                            key={row?.id || "data not found"}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell>
                              <Box
                                display="flex"
                                alignItems="center"
                                sx={{
                                  height: 50, // Tetapkan tinggi gambar
                                  overflow: 'hidden',
                                }}
                              >
                                <Box
                                  component="img"
                                  sx={{
                                    height: '100%', // Gunakan tinggi 100% agar gambar tetap
                                    display: 'block',
                                    marginRight: 2,
                                    flexShrink: 0, // Pastikan gambar tidak berubah ukuran saat judul melebihi dua baris
                                  }}
                                  src={row?.imgPath || `${process.env.PUBLIC_URL}/ggl_img.jpg`}
                                  alt={row?.name_id || "data not found"}
                                />
                                <Box
                                  flex="1"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                >
                                  <Typography variant="h5" fontWeight="600" style={{ textAlign: 'left' }}>
                                    {row?.name_id || "data not found"}
                                  </Typography>
                                  <Typography variant="h6" fontWeight="600" style={{ textAlign: 'left' }}>
                                    {formatDate(row?.date) || "data not found"}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell align="right">
                              <Typography variant="h6" fontWeight="600" >
                                {row?.ratanonton || "data not found"}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography variant="h6" fontWeight="600" >
                                {row?.penayangan || "data not found"}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>

              </Box>
              

              {/* side B */}
              <Box
                gridColumn="span 4"
                gridRow="span 6"
                backgroundColor={colors.primary[400]}
                overflow="auto"
                sx={{
                  boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',
                  borderRadius: '5px',
                  transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
                ':hover': {
                  transform: 'scale(1.02)',
                  backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
                },
                  }}
              >

                <Box
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  colors={colors.grey[100]}
                  p="15px"
                >
                
                  <>
                    <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
          
                    </Typography>
                    <Typography color={colors.grey[100]} variant="h6" >
                      Diperbarui secara real time
                    </Typography>
                    <hr style={{ margin: '20px 0' }} />

                    <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
                      10
                    </Typography>
                    <Typography color={colors.grey[100]} variant="h6" >
                      Subscriber
                    </Typography>

                    <hr style={{ margin: '20px 0' }} />


                    <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
                    {allPenayangan}
                    </Typography>
                    <Typography color={colors.grey[100]} variant="h6" >
                    Penayangan . 72 jam yang lalu
                    </Typography>
                    <hr style={{ margin: '20px 0' }} />
                  </>
                
                </Box>

                <Box
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  colors={colors.grey[100]}
                  p="15px"
                >
                  <>
                    <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
                      Konten terbaru
                    </Typography> <br />
                    <KontenTerbaruAnalytics />
                  </>
                </Box>
              </Box>
            </Box >
          </Fade>
    </>
  )
}

function FullRingkasanTab() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);
  const baseUrl = process.env.REACT_APP_API_URL;
  const apiAnalyticPenayangan = `${baseUrl}/chart/penayangan`;
  const apiChartWaktuTonton = `${baseUrl}/chart/watchtime`;
  const apiChartSubscriber = `${baseUrl}/chart/subscribe`;
  const [allPenayangan, setAllPenayangan] = useState('');
  const userId = localStorage.getItem("user_id");
  

  // api count All penayangan
  useEffect(() => {
    const allWatch = async () => {
      try {
        const response = await axios.get(`${baseUrl}/video/count_all_watch/${userId}`);
        const { data } = response.data;
        // Karena respons API berisi array data, Anda perlu mengakses elemen pertama dari array tersebut
        const allWatchData = data[0];
        // Kemudian, Anda dapat mengambil nilai last_watch_chanel dari objek tersebut
        const allWatchChannel = allWatchData.count_all_watch;
        setAllPenayangan(allWatchChannel);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    allWatch();
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: colors.primary[400], padding: 0 }} >
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          sx={{
            bgcolor: theme.palette.mode === 'light' ? '#FFFFFF' : '#121212',
            padding: 0,
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.mode === 'light' ? '#6870fa' : '#03dac5',
            },
            "& .Mui-selected": {
              color: theme.palette.mode === 'light' ? '#6870fa' : '#03dac5',
            },
            bgcolor: colors.primary[400], padding: 0 
          }}
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Penayangan" {...a11yProps(0)} style={{ color: colors.grey[200] }} />
          <Tab label="Waktu tonton (jam)" {...a11yProps(1)} style={{ color: colors.grey[200] }} />
          <Tab label="Subscriber" {...a11yProps(2)} style={{ color: colors.grey[200] }} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {/* Penayangan */}
        <TabPanel value={value} index={0} dir={theme.direction}>

          <Box
            gridColumn="span 12"
            gridRow="span 3"
            backgroundColor={colors.primary[400]}
          >
            <Box
              p="0 10px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                  title="Penayangan channel dibandingkan performa Anda biasanya. Seiring waktu, informasi ini dapat digunakan untuk mengenali video berperforma tinggi, memperkirakan perubahan musiman, dan menentukan waktu mengupload video baru. Penayangan ini mencakup video publik, pribadi, tidak publik, dan yang dihapus."
                >
                  Penayangan
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{ color: theme.palette.mode === "dark" ? '#f6f544' : '#6870fa'}}
                 
                  title="Penayangan channel dibandingkan performa Anda biasanya. Seiring waktu, informasi ini dapat digunakan untuk mengenali video berperforma tinggi, memperkirakan perubahan musiman, dan menentukan waktu mengupload video baru. Penayangan ini mencakup video publik, pribadi, tidak publik, dan yang dihapus."
                >
                  {allPenayangan}
                  <Icon sx={{ marginLeft: 1, width: 30, height: 30, paddingTop: 1 }}>
                    <TrendingUp
                      sx={{ fontSize: "30px", color: colors.greenAccent[500] }}
                    />
                  </Icon>
                </Typography>
                <Typography
                  variant="h6"

                  sx={{color: theme.palette.mode === 'light' ? '#6870fa' : '#03dac5'}}
                >
                  <i>Standar: 0 - {allPenayangan}</i>
                </Typography>
              </Box>
            </Box>
            {/* line chart penyangan */}
            <Box height="250px" m="-20px 0 0 0">
              <LineChartPenonton isDashboard={true} apiUrl={apiAnalyticPenayangan}/>
            </Box>
          </Box>
        </TabPanel>

        {/* Waktu tonton */}
        <TabPanel value={value} index={1} dir={theme.direction}>

          <Box
            gridColumn="span 12"
            backgroundColor={colors.primary[400]}
          >
            <Box
              mt="25px"
              p="0 10px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                  title="Waktu tonton channel dibandingkan performa Anda biasanya. Waktu tonton ini mencakup video publik, pribadi, tidak publik, dan yang dihapus."
                >
                  Waktu tonton (jam)
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                 
                  sx={{ color: theme.palette.mode === "dark" ? '#f6f544' : '#6870fa'}}

                   title="Waktu tonton channel dibandingkan performa Anda biasanya. Waktu tonton ini mencakup video publik, pribadi, tidak publik, dan yang dihapus."
                >
                  5,6
                  <Icon sx={{ marginLeft: 1, width: 30, height: 30, paddingTop: 1 }}>
                    <TrendingDown
                      sx={{ fontSize: "30px", color: colors.greenAccent[500] }}
                    />
                  </Icon>
                </Typography>

                <Typography
                  variant="h6"
                  color={colors.greenAccent[500]}
                >
                  <i>Standar: 6,0–9,0</i>
                </Typography>

              </Box>
              <Box>
                <IconButton>
                  <DownloadOutlinedIcon
                    sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                  />
                </IconButton>
              </Box>
            </Box>
            {/* Line chart waktu tonton */}
            <Box height="250px" m="-20px 0 0 0">
              <LineChartPenonton isDashboard={true} apiUrl={apiChartWaktuTonton}/>
            </Box>
          </Box>
        </TabPanel>

        {/* Subscriber */}
        <TabPanel value={value} index={2} dir={theme.direction}>

          <Box
            gridColumn="span 12"
            backgroundColor={colors.primary[400]}
          >
            <Box
              mt="25px"
              p="0 10px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                >
                  <span title="Perubahan jumlah subscriber dibandingkan dengan periode sebelumnya. Seiring waktu, data ini dapat digunakan untuk memahami alasan penonton men-subscribe atau unsubscribe channel Anda."> Rata-rata durasi tonton</span>
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{ color: theme.palette.mode === "dark" ? '#f6f544' : '#6870fa'}}
                  title="Perubahan jumlah subscriber dibandingkan dengan periode sebelumnya. Seiring waktu, data ini dapat digunakan untuk memahami alasan penonton men-subscribe atau unsubscribe channel Anda."
                >
                  -1
                  <Icon sx={{ marginLeft: 1, width: 30, height: 30, paddingTop: 1 }}>
                    <TrendingDown
                      sx={{ fontSize: "30px", color: colors.greenAccent[500] }}
                    />
                  </Icon>
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.greenAccent[500]}
                >
                  <i>75% lebih rendah daripada 28 hari sebelumnya</i>
                </Typography>

              </Box>
              <Box>
                <IconButton>
                  <DownloadOutlinedIcon
                    sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                  />
                </IconButton>
              </Box>
            </Box>
            {/* Line Chart Subscriber */}
            <Box height="250px" m="-20px 0 0 0">
              <LineChartPenonton isDashboard={true} apiUrl={apiChartSubscriber} />
            </Box>
          </Box>
        </TabPanel>
      </SwipeableViews>
    </Box >
  );
}


/////KontenTab\\\\
function KontenTab() {
  const [kontenPopuler, setKontenPopuler] = useState([]);
  const baseUrl = process.env.REACT_APP_API_URL;
  // api konten populer
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/video/periodvideopop`);
        setKontenPopuler(response.data.data);
      } catch (error) {
        throw error;
      }
    };

    fetchData();
  }, [baseUrl]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const color = theme.palette.mode === 'dark' ? '#000000' : '#ffffff';
  const background = theme.palette.mode === 'dark' 
  ? 'linear-gradient(135deg, #ffffff, #03dac5, #32b3a8)' 
  : '#6870FA';

  return (
    <>
    <Fade up>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
        sx={{
          borderRadius: '5px 5px 0 0',

        }}
        
      >



        {/* ROW 2 */}
        <Box
          gridColumn="span 12"
          gridRow="span 6"
          backgroundColor={colors.primary[400]}
          sx={{
            boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',
            borderRadius: '5px',
            transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
          ':hover': {
            transform: 'scale(1.02)',
            backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
          },
            
            }}
        >
          < FullKontenTab />

          <div style={{ padding: '0 10px' }}>
            <Typography color={colors.grey[100]} variant="h3" fontWeight="600" p={3} style={{ textAlign: 'center' }}>
              Konten populer Anda selama periode ini
            </Typography>
          </div>

          <div style={{ padding: '0 10px' }}>
            <TableContainer component={Paper} style={{ backgroundColor: colors.primary[400]  }}>
              <Table aria-label="simple table">
                <TableHead sx={{background}}>
                  <TableRow>
                    <TableCell>
                      <Typography color={color} variant="h5" fontWeight="600">
                        Konten
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography color={color} variant="h5" fontWeight="600" title="Frekuensi thumbnail video Anda ditampilkan kepada penonton. Metrik ini hanya menyertakan tayangan di Erklika, bukan di situs atau aplikasi eksternal.">
                        Tayangan
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography color={color} variant="h5" fontWeight="600" title="Total penayangan untuk rentang tanggal, wilayah, dan filter lain yang dipilih.">
                        Penayangan
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography color={color} variant="h5" fontWeight="600" title="Perkiraan rata-rata menit ditonton per penayangan untuk konten, rentang tanggal, wilayah, dan filter lain yang dipilih.">
                        Rata-rata durasi tonton
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography color={color} variant="h5" fontWeight="600" title="Estimasi total jam waktu penayangan konten Anda oleh penonton.">
                        Waktu tonton (jam)
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {kontenPopuler && kontenPopuler.map((row) => (
                    <TableRow
                      key={row?.id || "data not found"}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell >
                        <Box
                          display="flex"
                          sx={{
                            height: 50, // Tetapkan tinggi gambar
                            overflow: 'hidden',
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              height: 50,
                              height: '100%', // Gunakan tinggi 100% agar gambar tetap
                              display: 'block',
                              marginRight: 2,
                              flexShrink: 0,
                             
                            }}
                            src={row?.imgPath || `${process.env.PUBLIC_URL}/ggl_img.jpg`}
                            // alt={row?.name_id || "data not found"}
                          />
                          <Box>
                            <Typography color={colors.grey[100]} variant="h5" fontWeight="600" style={{ textAlign: 'left' }}>
                              {row?.name_id || "data not found"}
                            </Typography>
                            <Typography color={colors.grey[100]} variant="h6  " fontWeight="600" style={{ textAlign: 'left' }}>
                              {row?.date || "data not found"}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Typography color={colors.grey[100]} variant="h6" fontWeight="600"  >
                          {row?.tayangan || "data not found"}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography color={colors.grey[100]} variant="h6" fontWeight="600"  >
                          {row?.penayangan || "data not found"}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography color={colors.grey[100]} variant="h6" fontWeight="600"  >
                          {row?.ratanonton || "data not found"}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography color={colors.grey[100]} variant="h6" fontWeight="600"  >
                          {row?.waktutonton || "data not found"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Box>


      </Box >

    </Fade>
    </>
  )
}

function FullKontenTab() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);
  const baseUrl = process.env.REACT_APP_API_URL;
  const apiAnalyticPenayangan = `${baseUrl}/chart/penayangan`;
  const apiAnalyticTayangan = `${baseUrl}/chart/penayangan`;
  const apiChartDurasi = `${baseUrl}/chart/watchduration`;
  const [allPenayangan, setAllPenayangan] = useState('');
 
  const userId = localStorage.getItem("user_id");
  // api count All penayangan
  useEffect(() => {
    const allWatch = async () => {
      try {
        const response = await axios.get(`${baseUrl}/video/count_all_watch/${userId}`);
        const { data } = response.data;
        // Karena respons API berisi array data, Anda perlu mengakses elemen pertama dari array tersebut
        const allWatchData = data[0];
        // Kemudian, Anda dapat mengambil nilai last_watch_chanel dari objek tersebut
        const allWatchChannel = allWatchData.count_all_watch;
        setAllPenayangan(allWatchChannel);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    allWatch();
  }, []);
  

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: colors.primary[400], padding: 0,
  }} >
      <AppBar position="static" sx={{
        borderRadius: '5px 5px 0 0',

      }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{
            
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.mode === 'light' ? '#6870fa' : '#03dac5',
            },
            "& .Mui-selected": {
              color: theme.palette.mode === 'light' ? '#6870fa' : '#03dac5',
            },
            bgcolor: colors.primary[400], padding: 0,
            borderRadius: '5px 5px 0 0',

          }}
        >
          <Tab label="Penayangan" {...a11yProps(0)} style={{ color: colors.grey[200] }}/>
          <Tab label="Tayangan" {...a11yProps(1)} style={{ color: colors.grey[200] }}/>
          <Tab label="Rata-rata durasi nonton" {...a11yProps(2)} style={{ color: colors.grey[200] }} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>

          <Box
            gridColumn="span 12"
            backgroundColor={colors.primary[400]}
          >
            <Box
              mt="25px"
              p="0 10px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                  title="Penayangan channel dibandingkan performa Anda biasanya. Seiring waktu, informasi ini dapat digunakan untuk mengenali video berperforma tinggi, memperkirakan perubahan musiman, dan menentukan waktu mengupload video baru. Penayangan ini mencakup video publik, pribadi, tidak publik, dan yang dihapus."
                >
                  Penayangan
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{ color: theme.palette.mode === "dark" ? '#f6f544' : '#6870fa'}}
                  title="Penayangan channel dibandingkan performa Anda biasanya. Seiring waktu, informasi ini dapat digunakan untuk mengenali video berperforma tinggi, memperkirakan perubahan musiman, dan menentukan waktu mengupload video baru. Penayangan ini mencakup video publik, pribadi, tidak publik, dan yang dihapus."
                >
                  {allPenayangan}
                  <Icon sx={{ marginLeft: 1, width: 30, height: 30, paddingTop: 1 }}>
                    <TrendingUp
                      sx={{ fontSize: "30px", color: colors.greenAccent[500] }}
                    />
                  </Icon>
                </Typography>
              </Box>
              <Box>
                <IconButton>
                  <DownloadOutlinedIcon
                    sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                  />
                </IconButton>
              </Box>
            </Box>
            {/* Line chart penyangan(konten) */}
            <Box height="250px" m="-20px 0 0 0">
              <LineChartPenonton isDashboard={true} apiUrl={apiAnalyticPenayangan}/>
            </Box>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>

          <Box
            gridColumn="span 12"
            backgroundColor={colors.primary[400]}
          >
            <Box
              mt="25px"
              p="0 10px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                  title="Frekuensi thumbnail video Anda ditampilkan kepada penonton. Metrik ini hanya menyertakan tayangan di YouTube, bukan di situs atau aplikasi eksternal."
                >
                  Tayangan
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{ color: theme.palette.mode === "dark" ? '#f6f544' : '#6870fa'}}

                  title="Frekuensi thumbnail video Anda ditampilkan kepada penonton. Metrik ini hanya menyertakan tayangan di YouTube, bukan di situs atau aplikasi eksternal."
                >
                  180
                  <Icon sx={{ marginLeft: 1, width: 30, height: 30, paddingTop: 1 }}>
                    <TrendingDown
                      sx={{ fontSize: "30px", color: colors.greenAccent[500] }}
                    />
                  </Icon>
                </Typography>

                <Typography
                  variant="h6"
                  color={colors.greenAccent[500]}
                >
                  <i>40% lebih rendah daripada 28 hari sebelumnya </i>
                </Typography>

              </Box>
              <Box>
                <IconButton>
                  <DownloadOutlinedIcon
                    sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                  />
                </IconButton>
              </Box>
            </Box>
            <Box height="250px" m="-20px 0 0 0">
              <LineChartPenonton isDashboard={true} apiUrl={apiAnalyticPenayangan}/>
            </Box>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={2} dir={theme.direction}>

          <Box
            gridColumn="span 12"
            backgroundColor={colors.primary[400]}
          >
            <Box
              mt="25px"
              p="0 10px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                >
                  <span title="Perkiraan rata-rata menit ditonton per penayangan untuk konten, rentang tanggal, wilayah, dan filter lain yang dipilih."> Rata-rata durasi tonton</span>
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{ color: theme.palette.mode === "dark" ? '#f6f544' : '#6870fa'}}
                  title="Perkiraan rata-rata menit ditonton per penayangan untuk konten, rentang tanggal, wilayah, dan filter lain yang dipilih."
                >
                  1.06
                  <Icon sx={{ marginLeft: 1, width: 30, height: 30, paddingTop: 1 }}>
                    <TrendingDown
                      sx={{ fontSize: "30px", color: colors.greenAccent[500] }}
                    />
                  </Icon>
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.greenAccent[500]}
                >
                  <i>75% lebih rendah daripada 28 hari sebelumnya</i>
                </Typography>

              </Box>
              <Box>
                <IconButton>
                  <DownloadOutlinedIcon
                    sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                  />
                </IconButton>
              </Box>
            </Box>

            {/* Line chart watch duration */}
            <Box height="250px" m="-20px 0 0 0">
              <LineChartPenonton isDashboard={true}  apiUrl={apiChartDurasi} />
            </Box>
          </Box>
        </TabPanel>
      </SwipeableViews>
    </Box >
  );
}


////// PENONTON TAB\\\\
function PenontonTab() {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [penontonByKotaGender, setPenontonByKotaGender] = useState([]);
  const [penontonByMediaGender, setPenontonByMediaGender] = useState([]);
  const [penontonByUsiaGender, setPenontonByUsiaGender] = useState([]);

  // api Url Access
  const baseUrl = process.env.REACT_APP_API_URL;
  const apiPenontonKembali = `${baseUrl}/chart/rewatch`;
  const apiPenontonUnik = `${baseUrl}/chart/uniq`;
  const apiPenontonSubscribe = `${baseUrl}/chart/subscribe`;
  // api Get penonton by Kota dan gender
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/video/viewbycity`);
        setPenontonByKotaGender(response.data.data);
      } catch (error) {
        throw error;
      }
    };

    fetchData();
  }, [baseUrl]);

  // api get penonton by media dan gender
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/video/viewbymedia`);
        setPenontonByMediaGender(response.data.data);
      } catch (error) {
        throw error;
      }
    };

    fetchData();
  }, [baseUrl]);

  // api get penonton by usia dan gender
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/video/viewbyage`);
        setPenontonByUsiaGender(response.data.data);
      } catch (error) {
        throw error;
      }
    };

    fetchData();
  }, [baseUrl]);
  const color = theme.palette.mode === 'dark' ? '#000000' : '#ffffff';
  const background = theme.palette.mode === 'dark' 
  ? 'linear-gradient(135deg, #ffffff, #03dac5, #32b3a8)' 
  : '#6870FA';
  return (
    <>
    <Fade up>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >


   
        {/* ROW 2 */}
        <Box
          gridColumn="span 12"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          sx={{
            boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',
            borderRadius: '5px',
            transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
          ':hover': {
            transform: 'scale(1.02)',
            backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
          },
            }}
        >
          <FullPenontonTab />
        </Box>

        {/* USIA */}
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
          sx={{
            boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',
            borderRadius: '5px',
            transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
          ':hover': {
            transform: 'scale(1.02)',
            backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
          },
            }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            // borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Penonton menurut usia dan gender
            </Typography>
          </Box>
          <TableContainer component={Paper} style={{ backgroundColor: colors.primary[400] }}>
            <Table aria-label="simple table">
              <TableHead sx={{background}}>
                <TableRow>
                  <TableCell>
                    <Typography color={color} variant="h5" fontWeight="600">
                      Usia penonton
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color={color} variant="h5" fontWeight="600">
                      Wanita
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color={color} variant="h5" fontWeight="600">
                      Pria
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {penontonByUsiaGender && penontonByUsiaGender.map((row) => (
                  <TableRow
                    key={row?.age || "no data"}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell > {row?.age || "no data"} </TableCell>
                    <TableCell align="right">{row?.wanita || "no data"}</TableCell>
                    <TableCell align="right">{row?.pria || "no data"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* KOTA */}
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
          sx={{
            boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',
            borderRadius: '5px',
            transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
          ':hover': {
            transform: 'scale(1.02)',
            backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
          },
            }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            // borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600" >
              Penonton menurut kota dan gender
            </Typography>
          </Box>
          <TableContainer component={Paper} style={{ backgroundColor: colors.primary[400] }}>
            <Table aria-label="simple table">
              <TableHead sx={{background}}>
                <TableRow>
                  <TableCell>
                    <Typography color={color} variant="h5" fontWeight="600">
                      Kota
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color={color} variant="h5" fontWeight="600">
                      Wanita
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color={color} variant="h5" fontWeight="600">
                      Pria
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {penontonByKotaGender && penontonByKotaGender.map((row) => (
                  <TableRow
                    key={row?.kota  || "no data"}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell > {row?.kota  || "no data"} </TableCell>
                    <TableCell align="right">{row?.wanita  || "no data"}</TableCell>
                    <TableCell align="right">{row?.pria  || "no data"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* MEDIA */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
          sx={{
            boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',
            borderRadius: '5px',
            transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
          ':hover': {
            transform: 'scale(1.02)',
            backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
          },
            }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            // borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Penonton menurut media dan gender
            </Typography>
          </Box>
          <TableContainer component={Paper} style={{ backgroundColor: colors.primary[400] }}>
            <Table aria-label="simple table" >
              <TableHead sx={{background}}>
                <TableRow>
                  <TableCell>
                    <Typography color={color} variant="h5" fontWeight="600">
                      Media
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color={color} variant="h5" fontWeight="600">
                      Wanita
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color={color} variant="h5" fontWeight="600">
                      Pria
                    </Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {penontonByMediaGender && penontonByMediaGender.map((row) => (
                  <TableRow
                    key={row.media || "no data"}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell > {row.media || "no data"} </TableCell>
                    <TableCell align="right">{row.wanita || "no data"}</TableCell>
                    <TableCell align="right">{row.pria || "no data"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

      </Box>
        </Fade>
    </>
  )
}

function FullPenontonTab() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);

  const baseUrl = process.env.REACT_APP_API_URL;
  const apiPenontonKembali = `${baseUrl}/chart/rewatch`;
  const apiPenontonUnik = `${baseUrl}/chart/uniq`;
  const apiPenontonSubscribe = `${baseUrl}/chart/subscribe`;
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: colors.primary[400], padding: 0 }} >
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{
            
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.mode === 'light' ? '#6870fa' : '#03dac5',
            },
            "& .Mui-selected": {
              color: theme.palette.mode === 'light' ? '#6870fa' : '#03dac5',
            },
            bgcolor: colors.primary[400], padding: 0
          }} 
        >
          <Tab label="Penonton Kembali" {...a11yProps(0)} style={{ color: colors.grey[200] }}/>
          <Tab label="Penonton Unik" {...a11yProps(1)} style={{ color: colors.grey[200] }}/>
          <Tab label="Subscriber" {...a11yProps(2)} style={{ color: colors.grey[200] }}/>
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>

          <Box
            gridColumn="span 12"
            backgroundColor={colors.primary[400]}
          >
            <Box
              mt="25px"
              p="0 10px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                >
                  Penonton Kembali
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{ color: theme.palette.mode === "dark" ? '#f6f544' : '#6870fa'}}
                >
                  10.000
                </Typography>
              </Box>
              <Box>
                <IconButton>
                  <DownloadOutlinedIcon
                    sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                  />
                </IconButton>
              </Box>
            </Box>
            {/* linechart penonton kembali */}
           
            <Box height="250px" m="-20px 0 0 0">
              <LineChartPenonton isDashboard={true} apiUrl={apiPenontonKembali}/>
            </Box>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>

          <Box
            gridColumn="span 12"
            backgroundColor={colors.primary[400]}
          >
            <Box
              mt="25px"
              p="0 10px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                >
                  Penonton Unik
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{ color: theme.palette.mode === "dark" ? '#f6f544' : '#6870fa'}}

                >
                  180
                  <Icon sx={{ marginLeft: 1, width: 30, height: 30, paddingTop: 1 }}>
                    <TrendingDown
                      sx={{ fontSize: "30px", color: colors.greenAccent[500] }}
                    />
                  </Icon>
                </Typography>

                <Typography
                  variant="h6"
                  color={colors.greenAccent[500]}
                >
                  <i>40% lebih rendah daripada 28 hari sebelumnya </i>
                </Typography>

              </Box>
              <Box>
                <IconButton>
                  <DownloadOutlinedIcon
                    sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                  />
                </IconButton>
              </Box>
            </Box>
            {/* lineCart penonton uniq */}
            
            <Box height="250px" m="-20px 0 0 0">
              <LineChartPenonton isDashboard={true} apiUrl={apiPenontonUnik} />
            </Box>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={2} dir={theme.direction}>

          <Box
            gridColumn="span 12"
            backgroundColor={colors.primary[400]}
          >
            <Box
              mt="25px"
              p="0 10px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                >
                  Subscriber
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{ color: theme.palette.mode === "dark" ? '#f6f544' : '#6870fa'}}
                >
                  +2
                  <Icon sx={{ marginLeft: 1, width: 30, height: 30, paddingTop: 1 }}>
                    <TrendingUp
                      sx={{ fontSize: "30px", color: colors.greenAccent[500] }}
                    />
                  </Icon>
                </Typography>
                <Typography
                  variant="h6"
                  color={colors.greenAccent[500]}
                >
                  <i>75% lebih rendah daripada 28 hari sebelumnya</i>
                </Typography>

              </Box>
              <Box>
                <IconButton>
                  <DownloadOutlinedIcon
                    sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                  />
                </IconButton>
              </Box>
            </Box>
            {/* linechart subscriber */}
            <Box height="250px" m="-20px 0 0 0">
              <LineChartPenonton isDashboard={true} apiUrl={apiPenontonSubscribe}/>
            </Box>
          </Box>
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}




// const rowsKota = [
//   createData('Jakarta Selatan', 24, 30),
//   createData('Bekasi', 25, 46),
//   createData('Kota Depok', 3, 9.0),
//   createData('Tangerang', 6, 16.0),
//   createData('Solo', 7, 3),
//   createData('Madiun', 0, 16.0),
//   createData('Surabaya', 0, 16.0),
//   createData('Jakarta timur', 0, 16.0),
// ];

function KontenTerbaruAnalytics() {
  // api Url Access
  const baseUrl = process.env.REACT_APP_API_URL;

  const [videoTerbaru, setVideoTerbaru] = useState([]);

   //api video terbaru
   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/video/video_terbaru`);
        setVideoTerbaru(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [baseUrl]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeStep, setActiveStep] = React.useState(0);

  const maxSteps = videoTerbaru.length;
    
    
  const performa = videoTerbaru && videoTerbaru[activeStep]?.performa;
  const penayangan = videoTerbaru && videoTerbaru[activeStep]?.penayangan;
  const ratanonton = videoTerbaru && videoTerbaru[activeStep]?.ratanonton;
  const suka = videoTerbaru && videoTerbaru[activeStep]?.suka;
  const imgPath = videoTerbaru && videoTerbaru[activeStep]?.thumbnail_id;

  // Gunakan nilai default jika propertinya null atau tidak terdefinisi
  const performaValue = performa || 'Data not available';
  const penayanganValue = penayangan || 'Data not available';
  const ratanontonValue = ratanonton || 'Data not available';
  const sukaValue = suka || 'Data not available';
  const imgPathValue = imgPath || 'Data not available';

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>

      <Box
        component="img"
        sx={{
          height: 255,
          display: 'block',
          maxWidth: 400,
          overflow: 'hidden',
          width: '100%',
        }}
        src={imgPathValue}
        alt="Thumbnail "
      />
      <Box sx={{ maxWidth: 400, width: '100%', p: 2 }}>
        <Typography variant="h6"  >
          {performaValue}
        </Typography> <br />
        <TableContainer>
          <Table aria-label="simple table"  >
            <tr><td align="left" style={{ padding: 5 }}>Penayangan</td><td>{penayanganValue}</td></tr>
            <tr><td align="left" style={{ padding: 5 }}>Persentase penayangan rata-rata</td><td>{ratanontonValue}</td></tr>
            <tr><td align="left" style={{ padding: 5 }}>Suka</td><td>{sukaValue}</td></tr>
          </Table>
        </TableContainer>
      </Box>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === 2}
            sx={{ color: colors.grey[100], }}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}
            sx={{ color: colors.grey[100], }}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}




const Analytics = () => {
  const theme = useTheme();
  const baseUrl = process.env.REACT_APP_API_URL;
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const tokenss = localStorage.getItem('token_contributor');
  const md = localStorage.getItem('md');
  const userId = localStorage.getItem('user_id');
  const browser_id = localStorage.getItem('browser_id');
  const device_id = localStorage.getItem('device_id');
  const app_key = localStorage.getItem('app_key');

  useEffect(() => {
  
      // Panggil API untuk memeriksa status login
      fetchDataFromApi();
  }, []); // Jadikan array dependencies kosong agar useEffect hanya dijalankan sekali saat komponen dimount
  
  const fetchDataFromApi = async () => {
    try {

      const formdata = new FormData();
      // formdata.append("token_contributor",tokenss );
      formdata.append("id", userId );
      // formdata.append("md", md );
      // formdata.append("device_id", device_id);
      // formdata.append("app_key", app_key);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };

      const response = await fetch(`${baseUrl}/auth/check/`, requestOptions);
      const result = await response.json();
      console.log(result);
      setUserData(result); ;

      

      // Jika ditemukan user dengan status_log "online" yang memiliki user_id yang sama, tampilkan pop-up deteksi
      // if (result.status_log === userId && result.md !== md) {
      if (result?.user_id === userId && result?.status_log === "offline") {
        handleLogout();
        // setOpenPopup(true);
      }
        console.log(response.data);// Simpan data respons API ke state
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const handleLogout = async () => {
      try {
        
        const response = await fetch(`${baseUrl}/auth/signout/${userId}`, {
          method: 'POST',
          // Include any necessary headers or credentials for your API request
        });
  
        if (response.ok) {
          localStorage.removeItem('user_id');
          localStorage.removeItem('device_id');
          localStorage.removeItem('browser_id');
          localStorage.removeItem('app_key');
          localStorage.removeItem('md');
          localStorage.removeItem('token_contributor');
          setTimeout(() => {
            navigate('/login');
          }, 2000); 
        } else {
          // Handle errors or display appropriate messages
          console.error('Logout failed:', response.statusText);
        }
      } catch (error) {
        console.error('Error during logout:', error);
      }
    };


  


  return (
    <Box m="0 20px" sx={{
      position: 'fixed', 
      overflow: 'auto', 
      maxHeight: '100vh', 
      '&::-webkit-scrollbar': {
          display: 'none',
      }, 
      transition: 'width 0.3s ease',
      maxWidth:'100%',
      bottom: 5,
      top:70
    
  }}>
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="ANALYTICS" subtitle="Welcome to your dashboard" />
        
        {/* <Modal open={openPopup} onClose={() => setOpenPopup(false)} sx={{marginLeft:'800px'}}>
        <Box sx={{ backgroundColor: backgroundColor, width:'450px', borderRadius:'10px', padding:'20px', marginTop:'30px'}}>
          <h2>Deteksi Aktivitas Login</h2>
          <p>Aktivitas login dari perangkat lain <span style={{color:'red', fontweight:'bold'}}> {formattedMd}</span> terdeteksi.</p>
          <p>Apakah Anda ingin logout dari perangkat ini?</p>
          <Button 
              variant="contained"
              color="waterSea" 
              onClick={handleLogout}>Logout
          </Button>
        </Box>
      </Modal> */}
      </Box>
      <Box>
        <TabsUtama />
      </Box>


    </Box>
  );
};

export default Analytics;



const formatDate = (dateString) => {
  const options = {
    timeZone: 'Asia/Jakarta',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
  return formattedDate;
};