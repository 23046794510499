import { Box, Button, IconButton,Icon, Typography, useTheme } from "@mui/material";
 // Sesuaikan path dengan struktur proyek Anda
import { tokens } from "../../theme";
import * as React from 'react';
import { Fade } from "react-reveal";
import { useEffect, useState } from 'react';
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChartPenonton from "../../components/LineChartPenonton";
import Pie from "../../components/PieChart";
import RadarChart from "../../components/RadarCharts";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import TrendingUp from "@mui/icons-material/TrendingUp";
import TrendingDown from "@mui/icons-material/TrendingDown";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/custom.css'; 



const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [countVideo, setCountVideo] = useState([]);
  const [countVisitor, setCountVisitor] = useState([]);
  const [countComment, setCountComment] = useState([]);
  const [countSubscriber, setCountSubscriber] = useState([]);
  const [videoTerbaru, setVideoTerbaru] = useState([]);
  const fallbackImage = 'https://via.placeholder.com/150'; // Ganti dengan URL gambar placeholder Anda
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [dataRadar, setDataRadar] = useState([]);
  
  
  // api Url Access
  const baseUrl = process.env.REACT_APP_API_URL;
  const [userData, setUserData] = useState(null);

  const tokenss = localStorage.getItem('token_contributor');
  const md = localStorage.getItem('md');
  const browser_id = localStorage.getItem('browser_id');
  const device_id = localStorage.getItem('device_id');
  const app_key = localStorage.getItem('app_key');

  useEffect(() => {
  
      // Panggil API untuk memeriksa status login
      fetchDataFromApi();
  }, []); // Jadikan array dependencies kosong agar useEffect hanya dijalankan sekali saat komponen dimount
  
  // cek data login
  const fetchDataFromApi = async () => {
    try {

      const formdata = new FormData();
      // formdata.append("token_contributor",tokenss );
      formdata.append("id", userId );
      // formdata.append("md", md );
      // formdata.append("device_id", device_id);
      // formdata.append("app_key", app_key);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };

      const response = await fetch(`${baseUrl}/auth/check/`, requestOptions);
      const result = await response.json();
      console.log(result);
      setUserData(result); ;

      

      // Jika ditemukan user dengan status_log "online" yang memiliki user_id yang sama, tampilkan pop-up deteksi
      // if (result.status_log === userId && result.md !== md) {
      if (result?.user_id === userId && result?.status_log === "offline") {
        handleLogout();
        // setOpenPopup(true);
      }
        console.log(response.data);// Simpan data respons API ke state
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


    // api logout
    const handleLogout = async () => {
      try {
        
        const response = await fetch(`${baseUrl}/auth/signout/${userId}`, {
          method: 'POST',
          // Include any necessary headers or credentials for your API request
        });
  
        if (response.ok) {
          localStorage.removeItem('user_id');
          localStorage.removeItem('device_id');
          localStorage.removeItem('browser_id');
          localStorage.removeItem('app_key');
          localStorage.removeItem('md');
          localStorage.removeItem('token_contributor');
          setTimeout(() => {
            navigate('/login');
          }, 2000); 
        } else {
          // Handle errors or display appropriate messages
          console.error('Logout failed:', response.statusText);
        }
      } catch (error) {
        console.error('Error during logout:', error);
      }
    };

  const apiChartPenayangan = `${baseUrl}/chart/penayangan`;
  const apiTopView = `${baseUrl}/video/list_top_view`;

  const [allPenayangan, setAllPenayangan] = useState('');
 
  const userId = localStorage.getItem("user_id");
  // api count All penayangan
  useEffect(() => {
    const allWatch = async () => {
      try {
        const response = await axios.get(`${baseUrl}/video/count_all_watch/${userId}`);
        const { data } = response.data;
        // Karena respons API berisi array data, Anda perlu mengakses elemen pertama dari array tersebut
        const allWatchData = data[0];
        // Kemudian, Anda dapat mengambil nilai last_watch_chanel dari objek tersebut
        const allWatchChannel = allWatchData.count_all_watch;
        setAllPenayangan(allWatchChannel);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    allWatch();
  }, []);
  
//  video Top View
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/video/list_top_view`);
        const limitedData = response.data.data.slice(0, 5);
        setDataRadar(limitedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

// api count video
useEffect(() => {
  setLoading(true);

  const fetchData = async () => {
      try {
          const response = await fetch(`${baseUrl}/video/count_videos`);
          if (!response.ok) {
              throw new Error('Failed to fetch data');
          }
          const data = await response.json();
          setCountVideo(data.data.countvideos);
          setLoading(false);
      } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
      }
  };

  fetchData();
}, [baseUrl]);

  //api count visitor
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/video/count_visitor`);
        setCountVisitor(response.data.data.countvisitor);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [baseUrl]);


  //api count comment
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/coment/count_coment`);
        setCountComment(response.data.data.countcoment);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchData();
  }, [baseUrl]);

  //api count subscriber
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/users/count`);
        setCountSubscriber(response.data.data.countusers);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [baseUrl]);


  //api video terbaru
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/video/video_terbaru`);
        setVideoTerbaru(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [baseUrl]);


  function KontenTerbaruAnalytics() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = videoTerbaru.length;

    const performa = videoTerbaru && videoTerbaru[activeStep]?.performa;
    const penayangan = videoTerbaru && videoTerbaru[activeStep]?.penayangan;
    const ratanonton = videoTerbaru && videoTerbaru[activeStep]?.ratanonton;
    const suka = videoTerbaru && videoTerbaru[activeStep]?.suka;
    const imgPath = videoTerbaru && videoTerbaru[activeStep]?.thumbnail_id;

    // Gunakan nilai default jika propertinya null atau tidak terdefinisi
    const performaValue = performa || 'Data not available';
    const penayanganValue = penayangan || 'Data not available';
    const ratanontonValue = ratanonton || 'Data not available';
    const sukaValue = suka || 'Data not available';
    const imgPathValue = imgPath || 'Data not available';

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    

  
    return (
      <Box sx={{ maxWidth: 400, flexGrow: 1}}>
  
        <Box
          component="img"
          sx={{
            height: 255,
            display: 'block',
            maxWidth: 400,
            overflow: 'hidden',
            width: '100%',
          }}
          src={imgPathValue || fallbackImage}
          alt="tidak ada thumbnail"
        />
        <Box sx={{ maxWidth: 400, width: '100%', p: 2 }}>
          <Typography variant="h6"  >
            {performaValue}
          </Typography> <br />
          <TableContainer>
            <Table aria-label="simple table"  >
              <tr><td align="left" style={{ padding: 5 }}>Penayangan</td><td>{penayanganValue}</td></tr>
              <tr><td align="left" style={{ padding: 5 }}>Persentase penayangan rata-rata</td><td>{ratanontonValue}</td></tr>
              <tr><td align="left" style={{ padding: 5 }}>Suka</td><td>{sukaValue}</td></tr>
            </Table>
          </TableContainer>
        </Box>
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === 2 }
              sx={{ color: colors.grey[100], }}
            >
              Next
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}
              sx={{ color: colors.grey[100], }}>
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </Box>
    );
  }

  const boxShadowStyle = {
    boxShadow: '7px -4px 12px -1px rgba(0,0,0,0.22)',
    WebkitBoxShadow: '7px -4px 12px -1px rgba(0,0,0,0.22)',
    MozBoxShadow: '7px -4px 12px -1px rgba(0,0,0,0.22)'
  };
  const boxShadowStyle2 = {
    boxShadow: '7px -4px 12px -1px rgba(224,224,224,0.22)',
    WebkitBoxShadow: '7px -4px 12px -1px rgba(224,224,224,0.22)',
    MozBoxShadow: '7px -4px 12px -1px rgba(224,224,224,0.22)'
  };


 
  
  return (
    <Box m="0 20px" 
    >
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Dasbor channel" subtitle="Welcome to your dashboard" />

      </Box>

      {/* GRID & CHARTS */}
      <Fade right >
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        
            <Box
              gridColumn="span 3"
              // backgroundColor="linear-gradient(to right bottom, #00dc99, #2eca96, #42b992, #50a88c, #5b9684);"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                background:'linear-gradient(to right bottom, #00dc99, #2eca96, #42b992, #50a88c, #5b9684)',
                
                borderRadius: '8px !important',
                boxShadow: theme.palette.mode === 'dark' ? boxShadowStyle2 : boxShadowStyle,
                transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
                ':hover': {
                  transform: 'scale(1.02)',
                  backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
                },
                marginLeft: '5px'
              }}
              
            >

            
              <StatBox
              
                title={countVideo}
                subtitle="Videos"
                progress="0.50"
                // increase="+21%"
              
                icon={
                  <PointOfSaleIcon
                    sx={{  fontSize: "26px",
                    color:theme.palette.mode === 'light' ? '#ffffff':'#ffffff' }}
                  />
                }
              />
            </Box>
            
            <Box
              gridColumn="span 3"
              // backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            
              sx={{
                background:'linear-gradient(to right bottom, #dc0000, #d9342c, #d14f49, #c56563, #b57979)',
                borderRadius: '8px !important',
                boxShadow: theme.palette.mode === 'dark' ? boxShadowStyle2 : boxShadowStyle,
                transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
                ':hover': {
                  transform: 'scale(1.02)',
                  backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
                },
                // boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',

                // boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px #5AFFF8' : '0 0 8px 2px #AFC2C1' ,
              }}
            >
              <StatBox
                title={countSubscriber}
                subtitle="Subscriber"
                progress="0.30"
                // increase="+5%"
                icon={
                  <PersonAddIcon
                  sx={{  fontSize: "26px", backgroundImage: 'linear-gradient(to right #4b75e3, #5782e5, #648de6, #7299e7, #74a4ea, #7aaeec, #82b8ed, #83c3f0, #89cdf1, #92d7f2, #9ee0f2)', WebkitBackgroundClip: 'text', color: 'transparent', fontWeight: 'bold', display: 'inline-block' }}

                    sx={{ color: theme.palette.mode === 'light' ? '#ffffff' : '#ffffff', fontSize: "26px" }}
                  />
                }
              />
            </Box>
            <Box
              gridColumn="span 3"
              // backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              
              sx={{
                borderRadius: '8px !important',
                background:'linear-gradient(to right bottom, #a600ff, #be50ef, #cb7de2, #d0a5d6, #cdcbcd)',
                boxShadow: theme.palette.mode === 'dark' ? boxShadowStyle2 : boxShadowStyle,
                transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
                ':hover': {
                  transform: 'scale(1.02)',
                  backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
                },
                // boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',

                // boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px #5AFFF8' : '0 0 8px 2px #AFC2C1' ,
              }}
            >
              <StatBox
                title={countVisitor}
                subtitle="Visitor Traffic"
                progress="0.80"
                // increase="+43%"
                icon={
                  <TrafficIcon
                    sx={{ color: theme.palette.mode === 'light' ? '#ffffff':'#ffffff', fontSize: "26px" }}
                  />
                }
              />
            </Box>
            <Box
              gridColumn="span 3"
              // backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              
              sx={{
                borderRadius: '8px !important',
                background: 'linear-gradient(to right bottom, #0000ff, #464dfb, #6f76ef, #969bde, #bdbec7)',
                boxShadow: theme.palette.mode === 'dark' ? boxShadowStyle2 : boxShadowStyle,
                transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
                ':hover': {
                  transform: 'scale(1.02)',
                  backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
                },
                // boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',

                // boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px #5AFFF8' : '0 0 8px 2px #AFC2C1' ,
                marginRight: '5px'
              }}
            >
              <StatBox
                title={countComment}
                subtitle="Comments"
                progress="0.75"
                // increase="+14%"
                icon={
                  <EmailIcon
                    
                  sx={{ color: theme.palette.mode === 'light' ? '#ffffff' : '#ffffff',
                  fontSize: "26px" }}
                  />
                }
              />
            </Box>
        

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          
          sx={{ borderRadius:'8px !important',
          marginLeft:'5px',
          boxShadow: theme.palette.mode === 'dark' ? boxShadowStyle2 : boxShadowStyle,
          transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
          ':hover': {
            transform: 'scale(1.02)',
            backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
          },
          // boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',

          // boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px #5AFFF8' : '0 0 8px 2px #AFC2C1' ,
        }}
        >
          <Box
            mt="25px"
            
            p="0 50px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
           
           <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                  title="Penayangan channel dibandingkan performa Anda biasanya. Seiring waktu, informasi ini dapat digunakan untuk mengenali video berperforma tinggi, memperkirakan perubahan musiman, dan menentukan waktu mengupload video baru. Penayangan ini mencakup video publik, pribadi, tidak publik, dan yang dihapus."
                >
                  Penayangan
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{ color: theme.palette.mode === "dark" ? '#ffffff' : '#ffffff'}}
                  // color='#ffffff'
                  title="Penayangan channel dibandingkan performa Anda biasanya. Seiring waktu, informasi ini dapat digunakan untuk mengenali video berperforma tinggi, memperkirakan perubahan musiman, dan menentukan waktu mengupload video baru. Penayangan ini mencakup video publik, pribadi, tidak publik, dan yang dihapus."
                >
                  {allPenayangan}
                  <Icon sx={{ marginLeft: 1, width: 30, height: 30, paddingTop: 1 }}>
                    <TrendingUp
                      sx={{ fontSize: "30px", color: '#03dac5' }}
                    />
                  </Icon>
                </Typography>
                <Typography sx={{fontSize:'11px'}}
                >
                  <i>Standar: 0 - {allPenayangan}</i>
                </Typography>
              </Box>
            {/* <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box> */}
          </Box>
          <Box height="250px" m="-20px 0 0 0">
              <LineChartPenonton isDashboard={true} apiUrl={apiChartPenayangan} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
          
          overflow="auto"
          sx={{ borderRadius:'8px !important', 
          marginRight:'5px',
          transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
          ':hover': {
            transform: 'scale(1.02)',
            backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
          },
          // boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px #5AFFF8' : '0 0 8px 2px #AFC2C1' ,
          // boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',
          boxShadow: theme.palette.mode === 'dark' ? boxShadowStyle2 : boxShadowStyle,

        }}
     
        >
         <Box
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <>
              <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
                Performa video terbaru 
              </Typography> <br />
              <KontenTerbaruAnalytics />
            </>
          </Box>
        </Box>

        {/* ROW 3 */}
        <Box
      
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
          sx={{
            borderRadius: '8px !important',
            marginLeft:'5px',
            boxShadow: theme.palette.mode === 'dark' ? boxShadowStyle2 : boxShadowStyle,
            transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
            ':hover': {
              transform: 'scale(1.02)',
              backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
            },
            // boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',

            // boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px #5AFFF8' : '0 0 8px 2px #AFC2C1' ,
          }}
          
        >
          <Typography variant="h5" fontWeight="600">
            Campaign
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <Pie data={dataRadar}  />
          </Box>
        </Box> 
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="30px"
          sx={{ borderRadius:'8px !important',
          // boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px #5AFFF8' : '0 0 8px 2px #AFC2C1' ,
          // boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',
          boxShadow: theme.palette.mode === 'dark' ? boxShadowStyle2 : boxShadowStyle,
          transition: 'box-shadow 0.3s, transform 0.5s ease-in-out', // tambahkan transition untuk animasi smooth
          ':hover': {
            transform: 'scale(1.02)',
            backgroundColor: theme => theme.palette.mode === 'dark' ? colors.primary[500] : 'white', // atur warna latar belakang saat hover berdasarkan mode
          },
        }}
         
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
           Video Favorite
          </Typography>
          <Box >
            {/* <GeographyChart isDashboard={true} /> */}
            <RadarChart apiUrl={apiTopView}  />
            {/* <RadarChart data={dataRadar} /> */}

          </Box>
        </Box>

      </Box>
              </Fade>

        {/* Row baru */}
        {/* <Box sx={{width:'500px', backgroundColor:'grey', borderRadius:'10px'}} >
          <Pie data={dataRadar}  />
        </Box> */}
    </Box>
  );
};

export default Dashboard;
