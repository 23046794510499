import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import React from 'react';
import { mockDataKomentar, mockDataKonten } from "../../data/mockData";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Header from "../../components/Header";
import { Fade } from "react-reveal";


const Subscriber = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [allSubscriber, setAllSubscriber] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  
  // api Url Access
  const baseUrl = process.env.REACT_APP_API_URL;
  const [userData, setUserData] = useState(null);
  const tokenss = localStorage.getItem('token_contributor');
  const md = localStorage.getItem('md');
  const userId = localStorage.getItem('user_id');
  const browser_id = localStorage.getItem('browser_id');
  const device_id = localStorage.getItem('device_id');
  const app_key = localStorage.getItem('app_key');

  useEffect(() => {
  
      // Panggil API untuk memeriksa status login
      fetchDataFromApi();
  }, []); // Jadikan array dependencies kosong agar useEffect hanya dijalankan sekali saat komponen dimount
  
  const fetchDataFromApi = async () => {
    try {

      const formdata = new FormData();
      // formdata.append("token_contributor",tokenss );
      formdata.append("id", userId );
      // formdata.append("md", md );
      // formdata.append("device_id", device_id);
      // formdata.append("app_key", app_key);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };

      const response = await fetch(`${baseUrl}/auth/check/`, requestOptions);
      const result = await response.json();
      console.log(result);
      setUserData(result); ;

      

      // Jika ditemukan user dengan status_log "online" yang memiliki user_id yang sama, tampilkan pop-up deteksi
      // if (result.status_log === userId && result.md !== md) {
      if (result?.user_id === userId && result?.status_log === "offline") {
        handleLogout();
        // setOpenPopup(true);
      }
        console.log(response.data);// Simpan data respons API ke state
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const handleLogout = async () => {
      try {
        
        const response = await fetch(`${baseUrl}/auth/signout/${userId}`, {
          method: 'POST',
          // Include any necessary headers or credentials for your API request
        });
  
        if (response.ok) {
          localStorage.removeItem('user_id');
          localStorage.removeItem('device_id');
          localStorage.removeItem('browser_id');
          localStorage.removeItem('app_key');
          localStorage.removeItem('md');
          localStorage.removeItem('token_contributor');
          setTimeout(() => {
            navigate('/login');
          }, 2000); 
        } else {
          // Handle errors or display appropriate messages
          console.error('Logout failed:', response.statusText);
        }
      } catch (error) {
        console.error('Error during logout:', error);
      }
    };

  // api count video
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/users/list_subscribe`);
        // Tambahkan data dummy untuk usia, gender, dan hobi
        const modifiedData = response.data.data.map(subscriber => ({
          ...subscriber,
          age: 25, // Data dummy untuk usia
          gender: "Male", // Data dummy untuk gender
          hobby: "Reading", // Data dummy untuk hobi
        }));
        setAllSubscriber(modifiedData);
        setIsLoading(false);
      } catch (error) {
        throw error;
      }
    };

    fetchData();
  }, [baseUrl]);

  const columns = [
    {
      field: "imgAvatar",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => (
        <img
          //src={params.value } // asumsikan "imgAvatar" berisi URL gambar
          src="https://mui.com/static/images/avatar/1.jpg"
          alt={`Avatar of ${params.row.name}`}
          style={{ width: 35, height: 35, borderRadius: "50%" }}
        />
      )
    },
    {
      field: "full_name",
      headerName: "Nama",
      flex: 1,
      renderCell: (params) => params.value ? params.value : "Data kosong",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => params.value ? params.value : "Data kosong",
    },
    {
      field: "address",
      headerName: "Alamat",
      flex: 3,
      renderCell: (params) => params.value ? params.value : "Data kosong",
    },
    {
      field: "city",
      headerName: "Kota ",
      flex: 1,
      renderCell: (params) => params.value ? params.value : "Data kosong",
    },
    {
      field: "age",
      headerName: "Usia",
      flex: 1,
      renderCell: (params) => params.value ? params.value : "Data kosong",
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      renderCell: (params) => params.value ? params.value : "Data kosong",
    },
    {
      field: "hobby",
      headerName: "Hobi",
      flex: 1,
      renderCell: (params) => params.value ? params.value : "Data kosong",
    },
    {
      field: "createdAt",
      headerName: "Tanggal Bergabung",
      flex: 1,
      renderCell: (params) => {
        const rawDate = params.value;
        if (rawDate) {
          const formattedDate = new Date(rawDate).toLocaleDateString('id-ID', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
          return formattedDate;
        } else {
          return "Data kosong";
        }
      },
    },
  ];

  const toDetail = () => {
    navigate('/konten-detail');
  };

  return (
    <Box m="0 20px">
      <Header title="Subscriber channel" subtitle="List of Subscriber This Channel" />
      <Fade right>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            background: theme.palette.mode === 'dark' 
              ? 'linear-gradient(135deg, #ffffff, #03dac5, #32b3a8)' 
              : '#6870FA',
              color: theme.palette.mode === 'dark' ? '#000000' : '#ffffff',
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',


        }}
      >

        <DataGrid
          rows={allSubscriber}
          columns={columns}
          pageSize={10}
          pagination
          pageSizeOptions={[5, 10]}
          sx={{
            boxShadow: theme.palette.mode === 'dark' ? '0 0 8px 2px rgba(151,255,242,0.22)' : '0 0 8px 2px #A1A1A1',

          }}
        />

      </Box>
      </Fade>
    </Box>
  );
};

export default Subscriber;
