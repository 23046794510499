import React from 'react';
import { Container, Typography,Box, TextField, Button, CssBaseline, useTheme, Paper, Avatar, Link, Grid } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { tokens } from "../../theme";
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';

// import LogoProfile from "/assets/logo-erklika-biru.png";

const Register = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleLogin = () => {
    // Fungsi untuk menangani logika login
    console.log('Login clicked');
  };

  const handleGoogleLogin = () => {
    // Fungsi untuk menangani logika login dengan Google
    console.log('Google Login clicked');
  };

  const handleAppleLogin = () => {
    // Fungsi untuk menangani logika login dengan Apple
    console.log('Apple Login clicked');
  };

  return (
    <Box 
      component="main" 
      maxWidth="lg" 
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      sx={{
        backgroundImage: 'url(https://erklika.id/images/bg/bg-erklika.jpg)',
        backgroundPosition: 'center center',
        minWidth: '99vw',
        backgroundSize: 'fit',
    
      }}>
      <CssBaseline />

      {/* Box Login */}
        <Paper 
          sx={{
            display:'flex',
            flexDirection:'column',
            minWidth:'600px',
            padding:'20px',
            margin:' 50px auto',
            // height:'100vh', 
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Adjust the alpha value for transparency
            backdropFilter: 'blur(8px)', // Set the blur amount
            borderRadius:'10px'
          }}>
        <Box sx={{ m: 1, bgcolor: 'inherit.main', textAlign:'center' }}>
          <img src={`${process.env.PUBLIC_URL}/assets/logo-erklika-putih.png`} alt="ini img" style={{maxWidth:'150px'}} />
        </Box>
          <Typography component="h1" variant="h5" sx={{textAlign:'center', fontSize:'30px'}}>
            Daftar
          </Typography>
          <TextField
            margin="normal"
            required
            fillwidth
            // sx={{width:'40%'}}
            id="username"
            label="Username"
            name="username"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            // sx={{width:'40%'}}
            id="email"
            label="Email"
            name="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            // sx={{width:'40%'}}
            id="nomor_hp"
            label="Nomor Handphone"
            name="nomor_hp"
            autoFocus
          />
          <Box sx={{dispay:'flex', justifyContent:'space-between' }}>
            <TextField
              
              margin="normal"
              required
              sx={{mr:2, width:'48%'}}
              name="password"
              label="Password"
              type="password"
              id="password"
            />
              <TextField
              sx={{ width:'48%', marginLeft:'5px'}}
              
              margin="normal"
              required
              id="confirmPassword"
              label="Konfirmasi Password"
              name="confirmPassword"
              type="password"
              
            />
          </Box>
          <Button
            
            variant="contained"
            onClick={handleLogin}
            sx={{ mt: 3, mb: 2, backgroundImage: 'linear-gradient(to right, #3f37c9, #0066e4, #008bf0, #00acf2, #4cc9f0)', py:2, fontSize:'15px' }}
          >
            Daftar
          </Button>
            <Typography >
              Sudah memiliki akun?
              <Link component={RouterLink} to="/login" color="secondary"  sx={{cursor:'pointer'}} >
                Masuk
              </Link>
            </Typography>
      
        </Paper>

    
    </Box>
  );
};

export default Register;
