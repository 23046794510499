// PolarChartApex.js
import React from 'react';
import Chart from 'react-apexcharts';

const PolarChartApex = ({ data }) => {
  const series = data.map(video => parseInt(video.penayangan));

  const options = {
    chart: {
      type: 'polarArea',
      width: '100%', // Atur lebar chart menjadi 100% dari kontainer
      // height: 400,
    },
    // labels: data.map(video => video.title.name_id),
    fill: {
      opacity: 0.8,
      colors: ['#0077b6', '#03dac5', '#ff9505', '#df2935', '#996FCD'],
    },
    stroke: {
      width: 0,
    },
    yaxis: {
      show: false,
    },
    legend: {
      position: 'bottom',
    },
    // labels: {
    //   style: {
    //     fontSize: '8px', // Atur ukuran font label menjadi lebih kecil
    //   }
    // }
  };

  return (
    <Chart options={options} series={series} type="polarArea"  />
  );
};

export default PolarChartApex;
