import React, { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import { styled } from '@mui/material/styles';
import Header from "../../components/Header";
import ReactPlayer from 'react-player';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import {
  Grid,
  TextField,
  IconButton,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Snackbar,
  Alert
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';


const baseUrl = process.env.REACT_APP_API_URL;
const Add = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const [isCopied, setIsCopied] = useState(false);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [data, setData] = useState({
    name_id: '',
    description_id: '',
    // imgPath: null,
    filename_id: null,
    // visibility: '',
    // label: [],
    // tags: []
  });

  const handleVideoUpload = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      const maxSizeInBytes = 2 * 1024 * 1024 * 1024; // 2GB
      
      if (file.size > maxSizeInBytes) {
        alert("Ukuran file video terlalu besar. Maksimal 2GB.");
        e.target.value = null;
        setVideoPreview(null);
        return;
      }
  
      const videoURL = URL.createObjectURL(file);
      setVideoPreview(videoURL);
  
      // Set name_id, description_id, dan file video ke FormData
      const formData = new FormData();
      formData.append('name_id', data.name_id);
      formData.append('description_id', data.description_id);
      formData.append('filename_id', file);
  
      // Menyimpan FormData dalam state data
      setData((prevData) => ({
        ...prevData,
        videoFormData: formData,
      }));
    }
  };
  
  const handleThumbnailUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setThumbnailPreview(file);
      setData((prevData) => ({
        ...prevData,
        imgPath: file,
      }));
    }
  };

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleChangeSelect(name, value);
  };

  const handleChangeSelect = (name, value) => {
    setData((prevData) => ({
      ...prevData,
      [name]: Array.isArray(value) ? value : [value],
    }));
  };

  const createContent = async () => {
    try {
  
      const response = await fetch(`${baseUrl}/video/add`, {
        method: 'POST',
        body: data.videoFormData,
      });
     setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
        }, 1000);
      // ... (code lainnya)
    } catch (error) {
      // ... (code lainnya)
    }
  };

  return (
    <>
      <Box m="20px 0 20px 20px">
        <Header title="Add Content" subtitle="Add new Content video" />
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} variant="filled" severity="success">
            Video created successfully
          </Alert>
        </Snackbar>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                sx={{
                  "& > div": { paddingTop: '10px' },
                }}
              >
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={4}
                  variant="filled"
                  type="text"
                  label="Judul (wajib diisi)"
                  name="name_id"
                  onChange={handleChange}
                  sx={{ paddingTop: '10px' }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  multiline
                  rows={10}
                  type="text"
                  label="Deskripsi"
                  name="description_id"
                  onChange={handleChange}
                  required
                />

                <Box sx={{ textAlign: 'center', width: '100%', border: '1px dashed grey', borderRadius: '5px' }}>
                  <Typography style={{ textAlign: 'left', marginLeft: '10px' }}>Upload Video</Typography>
                  <label htmlFor="filename_id">
                    <IconButton component="span">
                      {videoPreview ? (
                        <ReactPlayer
                          width="300"
                          height="300"
                          url={videoPreview}
                          controls
                          sx={{borderRadius:'10px'}}
                        />
                      ) : (
                        <VideoCallIcon sx={{ fontSize: 120 }} />
                      )}
                    </IconButton>
                  </label>
                  <input
                    type="file"
                    id="filename_id"
                    onChange={(e) => {
                      handleVideoUpload(e);
                    }}
                    name="filename_id"
                    accept="video/*"
                    style={{ display: 'none' }}
                  />
                </Box>

                <Box sx={{ textAlign: 'center', width: '100%', border: '1px dashed grey', borderRadius: '5px', mb: 2 }}>
                  <Typography style={{ textAlign: 'left', marginLeft: '10px' }}>Upload Thumbnail</Typography>
                  <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                    <label htmlFor="thumbnail">
                      <IconButton component="span">
                        {thumbnailPreview ? (
                          <img
                            src={URL.createObjectURL(thumbnailPreview)}
                            alt="Thumbnail Preview"
                            style={{ maxWidth: '300px', maxHeight:'300px',  marginBottom:'20px', borderRadius:'5px' }}
                          />
                        ) : (
                          <CloudUploadIcon sx={{ fontSize: 100 }} />
                        )}
                      </IconButton>
                    </label>
                  </Box>
                  <VisuallyHiddenInput
                    type="file"
                    id="thumbnail"
                    onChange={(e) => {
                      handleThumbnailUpload(e);
                    }}
                    name="imgPath"
                    accept="image/*"
                  />
                </Box>
              </Box>     
            </Grid>

            <Grid item xs={4}>  
              <Box 
                gap="30px"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : 'span 4' },
                  marginTop: '10px',
                }}
              >
                <Card  sx={{ width:'95%' , gridColumn: 'span 4', mr:1 }}>
                  <CardMedia component="video" alt="Video" width="100%" height="140" controls >
                    {videoPreview ? (
                      <source src={videoPreview} type="video/mp4" />
                    ) : (
                      "Your browser does not support the video tag"
                    )}
                  </CardMedia>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      <Typography variant="body2" component="h5" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>Link Video</span>
                        <CopyToClipboard text={"link video"} onCopy={handleCopy}>
                          <Button size="small" startIcon={<FileCopyIcon style={{ color: '#4CAF50' }} />}>
                            <span style={{ color: isCopied ? '#4CAF50' : 'grey' }}>
                              {isCopied ? 'Copied!' : 'Copy Link'}
                            </span>
                          </Button>
                        </CopyToClipboard>
                      </Typography>
                      <Typography variant="h6">file video</Typography>
                    </Typography>
                  </CardContent>
                </Card>

                <MultipleSelect
                  label="Label"
                  options={['tk', 'sd', 'smp', 'smk', 'pt']}
                  selectedValues={data.label}
                  sx={{ gridColumn: 'span 4', mr:1}}
                  onChange={(selectedValues) => handleChangeSelect('label', selectedValues)}
                  />

                <MultipleSelect
                  label="Tags"
                  options={['tk', 'sd', 'smp', 'smk', 'pt']}
                  selectedValues={data.tags}
                  sx={{ gridColumn: 'span 4', mr:1}}
                  onChange={(selectedValues) => handleChangeSelect('tags', selectedValues)}
                />
              </Box>     
            </Grid>
          </Grid>
       
      </Box>
      <div style={{ marginLeft: '20px'}}>
        <Button type="button" color="secondary" variant="contained" onClick={createContent} sx={{width:'25%', mb:3}}>
          <IconButton color="inherit" size="large" onClick={''} sx={{mr:2}}>
            <EditIcon />
          </IconButton>
          <p style={{fontWeight:"bold"}}>Submit</p>
        </Button>
      </div>
    </>
  );
};

export default Add;

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const MultipleSelect = ({ label, options, selectedValues, onChange }) => {
  return (
    <FormControl sx={{ width:'95%', mt:4 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={selectedValues}
        onChange={(e) => onChange(e.target.value)}
        renderValue={(selected) => selected.join(', ')}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={selectedValues.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
